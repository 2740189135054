/*
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import translations from './locales/translations.js'

import App from './App.vue'
import router from './router'

const app = createApp(App)
const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages: translations
});
app.config.globalProperties.eventBus = app;


app.use(router)
app.use(i18n)

app.mount('#app')
*/
import Vue from 'vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import store from './store';
import axios from 'axios';
import router from './router/index.js'
import VueI18n from 'vue-i18n';
import translations from './locales/translations.js';

Vue.use(VueI18n)

axios.defaults.withCredentials=true;

const i18n = new VueI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages: translations
});

Vue.config.productionTip = false;
var DEBUG = false;
// ENABLE/DISABLE Console Logs
if(!DEBUG){
    console.log = function() {}
}
new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');