<template>
    <div class="prizes-wrapper d-flex flex-column justify-content-center align-items-center p-5 w-100">
       <div class="decoration-prize">
           <img src="@/assets/imgs/prize-decoration.svg" height="18px"/>
       </div>
        <div class="prize-container container d-flex flex-column justify-content-center align-items-center p-0 w-100">
           <div class="prizes-container container p-0">
               <h1 class="prize-title text-center text-white">{{$t('cash_prize')}}</h1>
               <h6 class="prize-subtitle text-right text-white mt-5">({{prizes[game].in_total}})</h6>
               <div class="prizes-items w-100">
                   <div class="prize-item d-flex justify-content-between p-2 mb-2" v-for="(prizeItem, index) in prizes[game].values" :key="index">
                       <h4 class="place-title px-1 m-0">{{prizeItem.place}}</h4>
                       <h4 class="value-title px-1 m-0">{{prizeItem.value}}</h4>
                   </div>
               </div>
           </div>
       </div>
    </div>
</template>
<script>
export default{
    name: "TournamentPrize",
    props:["game"],
    data(){
        return {
            prizes:{
                ea:{
                    in_total: this.$t("ea_fc_24.in_total_cash_prize"),
                    values:[
                        {
                            place: this.$t("ea_fc_24.first")+" "+this.$t("place"),
                            value: this.$t("ea_fc_24.prizes_values.first"),
                        },
                        {
                            place: this.$t("ea_fc_24.second")+" "+this.$t("place"),
                            value: this.$t("ea_fc_24.prizes_values.second"),
                        },
                        {
                            place:this.$t("ea_fc_24.third")+" "+this.$t("place"),
                            value: this.$t("ea_fc_24.prizes_values.third"),
                        }
                    ],
                },
                lol:{
                    in_total: this.$t("leagueOfLegends.in_total_cash_prize"),
                    values:[
                            {
                                place: this.$t("leagueOfLegends.first")+" "+this.$t("place"),
                                value: this.$t("leagueOfLegends.prizes_values.first"),
                            },
                            {
                                place: this.$t("leagueOfLegends.second")+" "+this.$t("place"),
                                value: this.$t("leagueOfLegends.prizes_values.second"),
                            },
                            {
                                place:this.$t("leagueOfLegends.third")+" "+this.$t("place"),
                                value: this.$t("leagueOfLegends.prizes_values.third"),
                            }
                    ],
                },
                pubg:{
                    in_total: this.$t("pubg.in_total_cash_prize"),
                    values:[
                        {
                            place: this.$t("pubg.first")+" "+this.$t("place"),
                            value: this.$t("pubg.prizes_values.first"),
                        },
                        {
                            place: this.$t("pubg.second")+" "+this.$t("place"),
                            value: this.$t("pubg.prizes_values.second"),
                        },
                        {
                            place:this.$t("pubg.third")+" "+this.$t("place"),
                            value: this.$t("pubg.prizes_values.third"),
                        },
                        {
                            place: this.$t("pubg.fourth")+" "+this.$t("place"),
                            value: this.$t("pubg.prizes_values.fourth"),
                        },
                        {
                            place: this.$t("pubg.fifth")+" "+this.$t("place"),
                            value: this.$t("pubg.prizes_values.fifth"),
                        },
                        {
                            place:this.$t("pubg.sixth")+" "+this.$t("place"),
                            value: this.$t("pubg.prizes_values.sixth"),
                        }
                    ],
                }
            }
        }
    }
}
</script>
<style scoped>

.prizes-wrapper{
    position: relative;
    clip-path: polygon(0 0, calc(100% - 56px) 0, 100% 56px, 100% 100%, 56px 100%, 0 calc(100% - 56px));
    background-color: var(--gray-bg-color);
    background-image: url("@/assets/imgs/tournament-prize-bg.png");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
}
.decoration-prize{
    position: absolute;
    right:10px;
    bottom: 10px;
}

.prize-title{
    text-transform: uppercase;
    font-family: 'StereoGothic-800';
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.5));
}
.prize-subtitle{
    color: white;
    font-family: 'StereoGothic-800';
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.5));
}
.prize-item{
    position: relative;
    background-color: white;
    clip-path: polygon(16px 0%, 100% 0%, calc(100% - 16px) 100%, 0% 100%);
    min-width: 300px;
}
.place-title{
    color: var(--main-color);
    font-family: 'StereoGothic-800';
}
.value-title{
    color: var(--main-color);
    font-family: 'StereoGothic-800';
}
@media screen and (max-width: 768px) {
    .prizes-wrapper{
        background-size: auto 100%;
        background-position: bottom right;
    }
    .place-title{
        font-size: 10pt;
    }
    .value-title{
        font-size: 10pt;
    }
  .prize-subtitle{
    font-size: 10pt;

  }

}
</style>