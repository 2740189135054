var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"lgame-elimination-bracket-container d-flex justify-content-center",style:({
            '--first-place': 'url('+require('@/assets/imgs/brackets/1st-place-medal.png')+')',
            '--second-place': 'url('+require('@/assets/imgs/brackets/2nd-place-medal.png')+')',
            '--third-place': 'url('+require('@/assets/imgs/brackets/3rd-place-medal.png')+')',
            '--trophy': 'url(' + require('@/assets/imgs/brackets/trophy-winner.png') + ')',
        })},[_c('div',{staticClass:"bracket-controls p-2"},[_c('button',{ref:"triggerDialog",attrs:{"data-toggle":"modal","data-target":"#lgame-report-score-dialog","hidden":""}})]),(_vm.players)?_c('div',{staticClass:"bracket-container-wrapper d-flex flex-column justify-content-start align-items-center"},[_c('div',{ref:"bracketcontainer",staticClass:"bracket-container d-flex flex-column justify-content-start align-items-start w-100 p-3"},[_c('div',{staticClass:"round-number-container d-flex align-items-center mb-2 ml-2"},_vm._l((_vm.players.length),function(index){return _c('div',{key:'round'+index,staticClass:"round-number d-flex justify-content-center align-items-center text-white"},[_vm._v(" Round "+_vm._s(index)+" ")])}),0),_c('div',{staticClass:"single-elimination-bracket d-flex flex-row position-relative",on:{"mousedown":function($event){return _vm.setDown(true, $event)},"mouseup":function($event){return _vm.setDown(false)},"mousemove":function($event){return _vm.setBracketPosition($event)}}},_vm._l((_vm.players),function(round,i){return _c('div',{key:i,staticClass:"bracket-round",class:['bracket-round-'+(i+1)]},_vm._l((round.games),function(matchItem,j){return _c('div',{key:j,staticClass:"match-container",class:[_vm.hasByes ? {
                           'preBye': i === 0 && _vm.players[1]['games'][matchItem.next].hasBye,
                           'noPreBye': i === 0 && !_vm.players[1]['games'][matchItem.next].hasBye,
                           'top' : i === 0 && !_vm.players[1]['games'][matchItem.next].hasBye && _vm.getClassName(j, matchItem.next, 'top'),
                           'bottom' : i === 0 && !_vm.players[1]['games'][matchItem.next].hasBye && _vm.getClassName(j, matchItem.next, 'bottom'),
                           'isBye' : matchItem.hasBye,
                        } : ''],style:([
                            i === 0 && _vm.hasByes? _vm.getMargin(j, matchItem.next) : '',
                        ])},[_c('span',{staticClass:"match-number pr-1"},[_vm._v(" "+_vm._s('#'+matchItem.seed)+" ")]),_c('div',{staticClass:"match"},[_c('div',{staticClass:"player-container participant-container",class:{
                                    'tournament-winner': _vm.winnerId && matchItem.match.participant.id === _vm.winnerId && i === _vm.players.length-1 && !_vm.isThirdPlaceEnabled,
                                    'first-place-winner' : _vm.winnerId && matchItem.match.participant.id === _vm.winnerId && i === _vm.players.length-1 && _vm.isThirdPlaceEnabled,
                                    'second-place-winner': _vm.secondPlaceId && _vm.secondPlaceId === matchItem.match.participant.id && i === _vm.players.length-1
                                }},[_c('div',{key:matchItem.match.participant.id,staticClass:"player",class:{
                                            'hover-item' : _vm.hover_item === matchItem.match.participant.id && matchItem.match.participant.id,
                                            'tournament-winner': _vm.winnerId && matchItem.match.participant.id === _vm.winnerId && i === _vm.players.length-1 && !_vm.isThirdPlaceEnabled,
                                            'first-place-winner' : _vm.winnerId && matchItem.match.participant.id === _vm.winnerId && i === _vm.players.length-1 && _vm.isThirdPlaceEnabled,
                                            'second-place-winner': _vm.secondPlaceId && _vm.secondPlaceId === matchItem.match.participant.id && i === _vm.players.length-1,
                                            'winner': matchItem.match.participant.winner,
                                            'lost': matchItem.match.competitor.winner,

                                        },on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(matchItem.match.participant.id)}}},[_c('div',{staticClass:"player-avatar text-center align-content-center"},[_c('div',{staticClass:"avatar-container"},[_c('img',{attrs:{"alt":"","width":"100%","height":"100%","src":_vm.avatar_dir+matchItem.match.participant.avatar},on:{"error":_vm.replaceImage}})])]),_c('div',{staticClass:"player-name px-2"},[_vm._v(" "+_vm._s(matchItem.match.participant.name)+" ")])]),_c('div',{staticClass:"player-score",class:{
                                            'winner': matchItem.match.participant.winner,
                                            'hover-item' : _vm.hover_item === matchItem.match.participant.id && matchItem.match.participant.id,
                                        },on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(matchItem.match.participant.id)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(matchItem.match.participant.score),expression:"matchItem.match.participant.score"}],attrs:{"type":"number","min":"0","maxlength":"2","readonly":""},domProps:{"value":(matchItem.match.participant.score)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(matchItem.match.participant, "score", $event.target.value)}}})])]),_c('div',{staticClass:"player-container competitor-container",class:{
                                'tournament-winner': _vm.winnerId && matchItem.match.competitor.id === _vm.winnerId && i === _vm.players.length-1 && !_vm.isThirdPlaceEnabled,
                                 'first-place-winner' : _vm.winnerId && matchItem.match.competitor.id === _vm.winnerId && i === _vm.players.length-1 && _vm.isThirdPlaceEnabled,
                                'second-place-winner': _vm.secondPlaceId && _vm.secondPlaceId === matchItem.match.competitor.id && i === _vm.players.length-1
                                }},[_c('div',{key:matchItem.match.competitor.id,staticClass:"player",class:{
                                        'winner': matchItem.match.competitor.winner,
                                        'hover-item' : _vm.hover_item === matchItem.match.competitor.id && matchItem.match.competitor.id,
                                        'tournament-winner': _vm.winnerId && matchItem.match.competitor.id === _vm.winnerId && i === _vm.players.length-1 && !_vm.isThirdPlaceEnabled,
                                         'first-place-winner' : _vm.winnerId && matchItem.match.competitor.id === _vm.winnerId && i === _vm.players.length-1 && _vm.isThirdPlaceEnabled,
                                        'second-place-winner': _vm.secondPlaceId && _vm.secondPlaceId === matchItem.match.competitor.id && i === _vm.players.length-1,
                                        'lost': matchItem.match.participant.winner,
                                        },on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(matchItem.match.competitor.id)}}},[_c('div',{staticClass:"player-avatar text-center align-content-center",class:{
                                           'winner': matchItem.match.competitor.winner,
                                        }},[_c('div',{staticClass:"avatar-container"},[_c('img',{attrs:{"alt":"","width":"100%","height":"100%","src":_vm.avatar_dir+matchItem.match.competitor.avatar},on:{"error":_vm.replaceImage}})])]),_c('div',{staticClass:"player-name px-2"},[_vm._v(" "+_vm._s(matchItem.match.competitor.name)+" ")])]),_c('div',{staticClass:"player-score",class:{
                                            'winner': matchItem.match.competitor.winner,
                                            'hover-item' : _vm.hover_item === matchItem.match.competitor.id && matchItem.match.competitor.id,
                                        },on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(matchItem.match.competitor.id)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(matchItem.match.competitor.score),expression:"matchItem.match.competitor.score"}],attrs:{"type":"number","min":"0","maxlength":"2","readonly":""},domProps:{"value":(matchItem.match.competitor.score)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(matchItem.match.competitor, "score", $event.target.value)}}})])])]),((i === _vm.players.length-1) && _vm.isThirdPlaceEnabled)?_c('div',{staticClass:"third-place-match-container p-2"},[_c('div',{staticClass:"third-match-content"},[_c('h6',{staticClass:"text-grey mb-2"},[_vm._v("Match de classement:")]),_c('div',{staticClass:"third-place-match"},[_c('div',{staticClass:"player-container participant-container",class:{'third-place-winner': _vm.thirdPlaceId && _vm.thirdPlaceId === _vm.thirdPlaceMatch.match.participant.id && _vm.thirdPlaceMatch.status !== 'pending'}},[_c('div',{staticClass:"player",class:{
                                                    'hover-item' : _vm.hover_item === _vm.thirdPlaceMatch.match.participant.id && _vm.thirdPlaceMatch.match.participant.id,
                                                    'third-place-winner': _vm.thirdPlaceId && _vm.thirdPlaceId === _vm.thirdPlaceMatch.match.participant.id && _vm.thirdPlaceMatch.status !== 'pending',
                                                     'winner': matchItem.match.participant.winner,
                                                     'lost': matchItem.match.competitor.winner,

                                                },on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(_vm.thirdPlaceMatch.match.participant.id)}}},[_c('div',{staticClass:"player-avatar text-center align-content-center",class:{
                                                   'winner': matchItem.match.participant.winner,
                                                }},[_c('div',{staticClass:"avatar-container"},[_c('img',{attrs:{"alt":"","width":"100%","height":"100%","src":_vm.avatar_dir+_vm.thirdPlaceMatch.match.participant.avatar},on:{"error":_vm.replaceImage}})])]),_c('div',{staticClass:"player-name px-2"},[_vm._v(" "+_vm._s(_vm.thirdPlaceMatch.match.participant.name)+" ")])]),_c('div',{staticClass:"player-score",class:{
                                                'winner': _vm.thirdPlaceMatch.match.participant.winner,'hover-item' : _vm.hover_item === _vm.thirdPlaceMatch.match.participant.id && _vm.thirdPlaceMatch.match.participant.id},on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(_vm.thirdPlaceMatch.match.participant.id)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.thirdPlaceMatch.match.participant.score),expression:"thirdPlaceMatch.match.participant.score"}],attrs:{"type":"number","min":"0","maxlength":"2","readonly":""},domProps:{"value":(_vm.thirdPlaceMatch.match.participant.score)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.thirdPlaceMatch.match.participant, "score", $event.target.value)}}})])]),_c('div',{staticClass:"player-container competitor-container",class:{'third-place-winner': _vm.thirdPlaceId && _vm.thirdPlaceId === _vm.thirdPlaceMatch.match.competitor.id && _vm.thirdPlaceMatch.status !== 'pending'}},[_c('div',{staticClass:"player",class:{
                                                    'hover-item' : _vm.hover_item === _vm.thirdPlaceMatch.match.competitor.id && _vm.thirdPlaceMatch.match.competitor.id,
                                                    'third-place-winner': _vm.thirdPlaceId && _vm.thirdPlaceId === _vm.thirdPlaceMatch.match.competitor.id && _vm.thirdPlaceMatch.status !== 'pending'
                                                },on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(_vm.thirdPlaceMatch.match.competitor.id)}}},[_c('div',{staticClass:"player-avatar text-center align-content-center",class:{
                                                   'winner': matchItem.match.competitor.winner,
                                                }},[_c('div',{staticClass:"avatar-container"},[_c('img',{attrs:{"alt":"","width":"100%","height":"100%","src":_vm.avatar_dir+_vm.thirdPlaceMatch.match.competitor.avatar},on:{"error":_vm.replaceImage}})])]),_c('div',{staticClass:"player-name px-2"},[_vm._v(" "+_vm._s(_vm.thirdPlaceMatch.match.competitor.name)+" ")])]),_c('div',{staticClass:"player-score",class:{'winner': _vm.thirdPlaceMatch.match.competitor.winner,'hover-item' : _vm.hover_item === _vm.thirdPlaceMatch.match.competitor.id && _vm.thirdPlaceMatch.match.competitor.id},on:{"mouseleave":function($event){return _vm.hoverItem(null)},"mouseover":function($event){return _vm.hoverItem(_vm.thirdPlaceMatch.match.competitor.id)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.thirdPlaceMatch.match.competitor.score),expression:"thirdPlaceMatch.match.competitor.score"}],attrs:{"type":"number","min":"0","maxlength":"2","readonly":""},domProps:{"value":(_vm.thirdPlaceMatch.match.competitor.score)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.thirdPlaceMatch.match.competitor, "score", $event.target.value)}}})])])])])]):_vm._e()])}),0)}),0)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }