<template>
    <div class="home-view-container">
        <NavBar/>
        <div class="hero-section-wrapper position-relative">
            <div class="hero-section d-flex flex-column flex-lg-row justify-content-around justify-content-lg-between align-items-center container pt-lg-5">
                <div class="hero-section-content pt-lg-2">
                    <div class="mge-x-frmje-container">
                        <img src="@/assets/imgs/logos/mge-x-frmje.svg" height="120px" alt="">
                    </div>
                    <div class="tournament-title-container my-4 my-lg-5">
                        <h1 class="tournament-title" v-html="$t('hero_section.tournamentTitle')"></h1>
                        <h6 class="tournament-date">{{$t("hero_section.tournamentDate")}}</h6>
                    </div>
                    <div class="games-vertical-banners d-flex d-lg-none justify-content-around align-items-center my-4">
                        <div class="pubg-v-banner-container v-banner-item" @click="goTo('eafc')">
                            <img src="@/assets/imgs/ea-v-banner.png" height="260px" alt="">
                        </div>
                        <div class="pubg-v-banner-container  v-banner-item" @click="goTo('pubg')">
                            <img src="@/assets/imgs/pubg-v-banner.png" height="260px" alt="">
                        </div>
                        <div class="pubg-v-banner-container v-banner-item" @click="goTo('lol')">
                            <img src="@/assets/imgs/lol-v-banner.png" height="260px" alt="">
                        </div>
                    </div>
                    <div class="hero-section-btn-container">
                        <a href="#about" class="hero-section-btn text-decoration-none px-5 py-2">{{$t("hero_section.discover")}}</a>
                    </div>
                    <div>
                        <ReglementSetter/>
                    </div>
                </div>
                <div class="games-vertical-banners d-none d-lg-flex justify-content-around align-items-center">
                    <div class="pubg-v-banner-container v-banner-item" @click="goTo('eafc')">
                        <img src="@/assets/imgs/ea-v-banner.png" height="360px" alt="">
                    </div>
                    <div class="pubg-v-banner-container  v-banner-item" @click="goTo('pubg')">
                        <img src="@/assets/imgs/pubg-v-banner.png" height="360px" alt="">
                    </div>
                    <div class="pubg-v-banner-container v-banner-item" @click="goTo('lol')">
                        <img src="@/assets/imgs/lol-v-banner.png" height="360px" alt="">
                    </div>
                </div>
            </div>
            <div class="logo-ministere-container">
                <img class="ministere-logo" src="@/assets/imgs/logos/ministere.svg" height="86px" alt="">
            </div>
        </div>
        <div class="about-container container my-5">
            <h2 class="section-title" dir="auto" id="about">{{$t("about_title")}}</h2>
            <p class="text-left about-desc">{{$t('about')}}</p>
        </div>
        <div class="container-fluid container-md px-0 px-lg-auto my-5">
            <PrizeBanner/>
        </div>
        <div class="tagLine-container container text-center my-5">
            <h1 class="big-question">{{$t("bigQuestion")}}</h1>
            <h4 class="get-ready">{{$t("getReady")}}</h4>
        </div>
        <div class="container mt-5">
          <h2 class="section-title" dir="auto">{{$t("timeLine.title")}}</h2>
        </div>
        <div class="timeline d-flex flex-column justify-content-center mb-5">
            <TimeLineSlider/>
        </div>
        <div class="game-cards-container container">
            <GameCardList/>
        </div>
        <div class="stream container my-5" id="stream">
            <h2 class="section-title" dir="auto">{{$t("stream")}}</h2>
            <StreamComp/>
        </div>
        <div class="bracket container my-5" id="bracket">
            <h2 class="section-title" dir="auto">{{$t("brackets")}}</h2>
            <BracketViewer :has-header="true"/>
        </div>
    </div>
</template>

<script>
import PrizeBanner from '@/components/Prize/PrizeBanner.vue'
import GameCardList from '@/components/Cards/GameCardList.vue'
import StreamComp from '@/components/Stream/StreamComp.vue'
import BracketViewer from '@/components/Brackets/BracketViewer.vue'
import TimeLineSlider from '@/components/TimeLine/TimeLineSlider.vue'
import NavBar from '@/components/Nav/NavBar.vue'
import ReglementSetter from '@/components/Partials/ReglementSetter.vue'
export default {
    name: 'HomeView',
    components:{
        NavBar,
        PrizeBanner,
        TimeLineSlider,
        GameCardList,
        StreamComp,
        BracketViewer,
        ReglementSetter
    },
    methods:{
        goTo(game){
            switch (game){
                case 'eafc':
                  this.$router.push("/eafc24");
                  break;
                case 'lol':
                  this.$router.push("/lol");
                  break;
                case 'pubg':
                  this.$router.push("/pubgmobile");
                  break;
                default:
                  this.$router.push("/");
            }
        }
    },
    mounted(){
        const resizeOps = () => {
            document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
            this.isMobile = window.innerWidth <= 768 ? true : false;
        };

        resizeOps();
        window.addEventListener("resize", resizeOps);

    }
}
</script>
<style scoped>
.hero-section-wrapper{
    background-color: var(--gray-bg-color);
    background-image: url("@/assets/imgs/home-hero-bg.png");
}
.hero-section{
    min-height: calc(var(--vh, 1vh) * 100);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 16px;
}
.tournament-title{
    color: white;
    font-family: "sqwaredblack" !important;
    text-transform: uppercase;
}
.tournament-date{
    color: white;
    font-family: "sqwaredregular" !important;

}
.hero-section-btn {
    background-color: var(--main-color);
    outline: none;
    border: none;
    color: white;
    font-family: "sqwaredblack" !important;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
}
.hero-section-btn:hover{
    transform: scale(1.01);
    background-color: #ff3f1a;
}
    .about-desc{
    color: black;
    font-family: "sqwaredsemi_bold";
}
.section-title{
    color: var(--main-color);
    font-family: "sqwaredblack" !important;
    text-transform: uppercase;
}
.timeline{
    background-image: url('@/assets/imgs/timeline-bg.webp');
    background-size: 100% 100%;
    background-position: center center;
    min-height: 400px;
}
.big-question{
    color: black;
    font-family: "Sqwaredblack", sans-serif;
}
.get-ready{
    color: black;
    font-family: "sqwaredsemi_bold" !important;
}
.logo-ministere-container{
    position: absolute;
    bottom: 32px;
    right: 32px;
}
.v-banner-item:hover{
    transform: scale(1.01);
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .hero-section{

    }
    .section-title {
        font-size: 18pt;
    }
    .hero-section-wrapper {
        background-size: auto 100%;
        background-position: left top;
    }
    .section-title {
        font-size: 18pt;
    }
    .mge-x-frmje-container img{
        height: 80px;
    }
    .hero-section-content{
        text-align: center;
    }
    .logo-ministere-container {
        bottom: 10px;
        right: 10px;
    }
    .logo-ministere-container img{
        height: 48px;
    }
    .disclaimer p{
        font-size: 7pt;
    }
    .game-logo img{
        height: 38px;
    }
    .krafton-container{
        left: 0px;
        bottom: 0px;
    }
    .krafton-container img{
        height: 28px;
    }
    .pubg-esport-container img{
        height: 42px;
    }
    .perso-container{
        left: -26px;
    }
    .perso-container img{
        height: 110px;
    }
    .v-banner-item img{
        height:180px;
    }
  .tournament-title{
    font-size: 26pt;
  }
}
@media screen and (max-height: 580px){
  .hero-section{
    min-height: 900px;
  }
  .logo-ministere-container img{
    height: 68px;
  }
}
</style>
