
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue'
import EAFC24View from '@/views/EAFC24View.vue'
import LeagueOfLegendsView from '@/views/LeagueOfLegendsView.vue'
import PUBGMobileView from '@/views/PUBGMobileView.vue'

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/eafc24',
            name: 'eafc24',
            component: EAFC24View
        },
        {
            path: '/pubgmobile',
            name: 'pubgmobile',
            component: PUBGMobileView
        },
        //define a fallback route

        {
            path: '/lol',
            name: 'lol',
            component: LeagueOfLegendsView

        },
        {
            path: '/:catchAll(.*)',
            component:HomeView,
        }
    ]
})

export default router
