<template>
    <div class="lgame-battle-royal-bracket-wrapper p-1 p-md-4 h-100 w-100">
        <div class="tabs-header-container d-flex justify-content-start align-items-center pr-2 pr-md-4"
            v-if="bracket.final_phase.length > 0">
            <button class="br-bracket-tab-btn py-1 px-2 px-md-4" :class="{ 'btn-active': currentPhaseTab === TAB_GRP_PHASE }"
                @click="setTab(TAB_GRP_PHASE, 1)">
                Phase groupes
            </button>
            <button class="br-bracket-tab-btn py-1 px-2 px-md-4 mr-2"
                :class="{ 'btn-active': currentPhaseTab === TAB_FINAL_PHASE }" @click="setTab(TAB_FINAL_PHASE, 1)">
                Phase finale
            </button>
        </div>
        <div class="lgame-battle-royal-bracket-container p-1 p-md-4"
            :class="{ 'tabs-content-container': bracket.final_phase }">
            <div class="group-phase-tab tab-content-item" v-if="currentPhaseTab === TAB_GRP_PHASE">
                <h5 class="grp-number text-uppercase">Bracket PHASE DE POULES</h5>
                <div class="group-items-container group-list row row-cols-3 m-0 p-2 w-100" v-if="!grpSelected">
                    <div class="col col-12 col-md-4 group-item mx-1 p-1 mb-2"
                        v-for="(group, grpIndex) in bracket.group_phase" @click="setActiveGrp(group, grpIndex)"
                        :key="`grp-${group.grp}`">
                        <h5 class="grp-number text-uppercase p-1">Groupe {{ group.grp }}</h5>
                        <table class="w-100">
                            <thead>
                                <tr>
                                    <th class="px-2 w-75">Participants</th>
                                    <th class="text-center px-2">Score</th>
                                    <th class="text-center px-2">Rank</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="px-2 participant-item" v-for="participant in group.participants.slice(0, 5)"
                                    :key="participant.id">
                                    <td class="px-2 py-1 w-75">
                                        <div class="d-flex align-items-center">
                                            <div class="participant-avatar-container mr-2">
                                                <img alt="" :src="avatarDir + participant.avatar"
                                                    @error.once="replaceImage" />
                                            </div>
                                            <h6 class="participant-name font-weight-normal m-0">{{ participant.name }}</h6>
                                        </div>
                                    </td>
                                    <td class="text-center px-2 py-1">{{ participant.score }}</td>
                                    <td class="text-center px-2 py-1">
                                        <p class="text-center m-0 mx-auto">
                                            {{
                                                participant.rank ? (participant.rank > 3 ? `${participant.rank}` :
                                                    participant.rank) : 'N/A'
                                            }}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100" v-if="group.participants.length - 5 > 0">
                                <p class="font-weight-normal text-white-50 p-1 m-0">+{{ group.participants.length - 5 }} Autre{{
                                    group.participants.length - 5 > 1 ? 's' : '' }} participant{{ group.participants.length - 5
        > 1 ? 's' : '' }}</p>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="group-item-overview" v-if="grpSelected">
                    <div class="d-flex justify-content-end align-items-center mb-2 w-100">
                        <div class="btn-back-grp-container d-flex justify-content-center align-items-center px-4 py-1"
                            @click="setActiveGrp(null, null)">
                            <img class="mr-2" :src="grpListImg" width="16px" height="16px" alt="" />
                            <button class="btn-back-grp text-uppercase">
                                groupes
                            </button>
                        </div>
                    </div>
                    <div class="tabs-header-container d-flex justify-content-start align-items-center pr-2 pr-md-4">
                        <button class="br-bracket-tab-btn py-1 px-2 px-md-4"
                            :class="{ 'btn-active': currentTab === TAB_GRP_OVERVIEW }" @click="setTab(TAB_GRP_OVERVIEW, 2)">
                            Classement général
                        </button>
                        <button class="br-bracket-tab-btn py-1 px-2 px-md-4 mr-2"
                            :class="{ 'btn-active': currentTab === TAB_GRP_GAMES }" @click="setTab(TAB_GRP_GAMES, 2)">
                            Manches
                        </button>
                    </div>
                    <div class="tabs-content-container p-2 p-md-4">
                        <div class="group-overview-tab tab-content-item" v-if="currentTab === TAB_GRP_OVERVIEW">
                            <h5 class="grp-number text-uppercase p-1">Groupe {{ bracket.group_phase[activeGrpIndex].grp }}
                            </h5>
                            <div class="participants-table">
                                <table class="w-100">
                                    <thead>
                                        <tr>
                                            <th class="px-2 w-75">Participants</th>
                                            <th class="text-center px-2">Score</th>
                                            <th class="text-center px-2">Rank</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="px-2 participant-item"
                                            v-for="participantItem in bracket.group_phase[activeGrpIndex].participants"
                                            :key="participantItem.id">
                                            <td class="px-2 py-1">
                                                <div class="d-flex align-items-center">
                                                    <div class="participant-avatar-container mr-2">
                                                        <img alt="" :src="avatarDir + participantItem.avatar"
                                                            @error.once="replaceImage" />
                                                    </div>
                                                    <h6 class="participant-name font-weight-normal m-0">
                                                        {{ participantItem.name }}</h6>
                                                </div>
                                            </td>
                                            <td class="text-center px-2 py-1">{{ participantItem.score }}</td>
                                            <td class="text-center px-2 py-1">
                                                <p class="text-center m-0 mx-auto"
                                                    :class="[participantItem.rank <= 3 ? getRankClass(participantItem.rank) : '']">
                                                    {{
                                                        participantItem.rank ? (participantItem.rank > 3 ?
                                                            `${participantItem.rank} ème` : participantItem.rank) : 'N/A'
                                                    }}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="group-games-tab tab-content-item" v-if="currentTab === TAB_GRP_GAMES">
                            <h5 class="grp-number text-uppercase p-1">Groupe {{ bracket.group_phase[activeGrpIndex].grp }}
                            </h5>
                            <div class="game-selector-container d-flex align-items-center my-2">
                                <h6 class="game-selector-label m-0 mr-2">Manche: </h6>
                                <select class="game-selector px-2 py-1" v-model="currentGameIndex"
                                    @change="setCurrentGame(currentGameIndex)">
                                    <option v-for="(game, index) in bracket.group_phase[activeGrpIndex].games"
                                        :key="`manche-${index}`" :value="index">
                                        Manche {{ index }}
                                    </option>
                                </select>
                            </div>
                            <div class="active-game-participants text-white">
                                <div class="participants-table">
                                    <table class="w-100">
                                        <thead>
                                            <tr>
                                                <th class="px-2 w-50">Participants</th>
                                                <th class="text-center px-2">Kills</th>
                                                <th class="text-center px-2">In game Rank</th>
                                                <th class="text-center px-2">Score</th>
                                                <th class="text-center px-2">Rank</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="px-2 participant-item"
                                                v-for="participantItem in bracket.group_phase[activeGrpIndex].games[currentGameIndex]"
                                                :key="participantItem.id">
                                                <td class="px-2 py-1 w-50">
                                                    <div class="d-flex align-items-center">
                                                        <div class="participant-avatar-container mr-2">
                                                            <img alt="" :src="avatarDir + participantItem.avatar"
                                                                @error.once="replaceImage" />
                                                        </div>
                                                        <h6 class="participant-name font-weight-normal m-0">
                                                            {{ participantItem.name }}</h6>
                                                    </div>
                                                </td>
                                                <td class="text-center px-2 py-1">{{ participantItem.kills }}</td>
                                                <td class="text-center px-2 py-1">{{ participantItem.in_game_rank ?
                                                    participantItem.in_game_rank : 'N/A' }}</td>
                                                <td class="text-center px-2 py-1">{{ participantItem.score }}</td>
                                                <td class="text-center px-2 py-1 ">
                                                    <p class="text-center m-0 mx-auto"
                                                        :class="[participantItem.rank <= 3 ? getRankClass(participantItem.rank) : '']">
                                                        {{
                                                            participantItem.rank ? (participantItem.rank > 3 ?
                                                                `${participantItem.rank} ème` : participantItem.rank) : 'N/A'
                                                        }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="final-phase-tab tab-content-item" v-if="currentPhaseTab === TAB_FINAL_PHASE">
                <h5 class="grp-number text-uppercase">Bracket phase finale</h5>
                <div class="group-items-container group-list row row-cols-3 m-0 p-2 w-100" v-if="!grpSelected">
                    <div class="col col-12 col-md-4 group-item mx-1 p-1 mb-2"
                        v-for="(group, grpIndex) in bracket.final_phase" @click="setActiveGrp(group, grpIndex)"
                        :key="`grp-${group.grp}`">
                        <h5 class="grp-number text-uppercase p-1">Groupe {{ group.grp }}</h5>
                        <table class="w-100">
                            <thead>
                                <tr>
                                    <th class="px-2 w-75">Participants</th>
                                    <th class="text-center px-2">Score</th>
                                    <th class="text-center px-2">Rank</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="px-2 participant-item" v-for="participant in group.participants.slice(0, 5)"
                                    :key="participant.id">
                                    <td class="px-2 py-1 w-75">
                                        <div class="d-flex align-items-center">
                                            <div class="participant-avatar-container mr-2">
                                                <img alt="" :src="avatarDir + participant.avatar"
                                                    @error.once="replaceImage" />
                                            </div>
                                            <h6 class="participant-name font-weight-normal m-0">{{ participant.name }}</h6>
                                        </div>
                                    </td>
                                    <td class="text-center px-2 py-1">{{ participant.score }}</td>
                                    <td class="text-center px-2 py-1">
                                        <p class="text-center m-0 mx-auto">
                                            {{
                                                participant.rank ? (participant.rank > 3 ? `${participant.rank}` :
                                                    participant.rank) : 'N/A'
                                            }}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100" v-if="group.participants.length - 5 > 0">
                                <p class="font-weight-normal text-white-50 p-1 m-0">+{{ group.participants.length - 5 }} Autre{{
                                    group.participants.length - 5 > 1 ? 's' : '' }} participant{{ group.participants.length - 5
        > 1 ? 's' : '' }}</p>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="group-item-overview" v-if="grpSelected">
                    <div class="d-flex justify-content-end align-items-center mb-2 w-100">
                        <div class="btn-back-grp-container d-flex justify-content-center align-items-center px-4 py-1"
                            @click="setActiveGrp(null, null)">
                            <img class="mr-2" :src="grpListImg" width="16px" height="16px" alt="" />
                            <button class="btn-back-grp text-uppercase">
                                groupes
                            </button>
                        </div>

                    </div>
                    <div class="tabs-header-container d-flex justify-content-start align-items-center pr-2 pr-md-4">
                        <button class="br-bracket-tab-btn py-1 px-2 px-md-4"
                            :class="{ 'btn-active': currentTab === TAB_GRP_OVERVIEW }" @click="setTab(TAB_GRP_OVERVIEW, 2)">
                            Classement général
                        </button>
                        <button class="br-bracket-tab-btn py-1 px-2 px-md-4 mr-2"
                            :class="{ 'btn-active': currentTab === TAB_GRP_GAMES }" @click="setTab(TAB_GRP_GAMES, 2)">
                            Manches
                        </button>
                    </div>
                    <div class="tabs-content-container p-2 p-md-4">
                        <div class="group-overview-tab tab-content-item" v-if="currentTab === TAB_GRP_OVERVIEW">
                            <h5 class="grp-number text-uppercase p-1">Groupe {{ bracket.final_phase[activeGrpIndex].grp }}
                            </h5>
                            <div class="participants-table">
                                <table class="w-100">
                                    <thead>
                                        <tr>
                                            <th class="px-2 w-75">Participants</th>
                                            <th class="text-center px-2">Score</th>
                                            <th class="text-center px-2">Rank</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="px-2 participant-item"
                                            v-for="participantItem in bracket.final_phase[activeGrpIndex].participants"
                                            :key="participantItem.id">
                                            <td class="px-2 py-1">
                                                <div class="d-flex align-items-center">
                                                    <div class="participant-avatar-container mr-2">
                                                        <img alt="" :src="avatarDir + participantItem.avatar"
                                                            @error.once="replaceImage" />
                                                    </div>
                                                    <h6 class="participant-name font-weight-normal m-0">
                                                        {{ participantItem.name }}</h6>
                                                </div>
                                            </td>
                                            <td class="text-center px-2 py-1">{{ participantItem.score }}</td>
                                            <td class="text-center px-2 py-1">
                                                <p class="text-center m-0 mx-auto"
                                                    :class="[participantItem.rank <= 3 ? getRankClass(participantItem.rank) : '']">
                                                    {{
                                                        participantItem.rank ? (participantItem.rank > 3 ?
                                                            `${participantItem.rank} ème` : participantItem.rank) : 'N/A'
                                                    }}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="group-games-tab tab-content-item" v-if="currentTab === TAB_GRP_GAMES">
                            <h5 class="grp-number text-uppercase p-1">Groupe {{ bracket.final_phase[activeGrpIndex].grp }}
                            </h5>
                            <div class="game-selector-container d-flex align-items-center my-2">
                                <h6 class="game-selector-label m-0 mr-2">Manche: </h6>
                                <select class="game-selector px-2 py-1" v-model="currentGameIndex"
                                    @change="setCurrentGame(currentGameIndex)">
                                    <option v-for="(game, index) in bracket.final_phase[activeGrpIndex].games"
                                        :key="`manche-${index}`" :value="index">
                                        Manche {{ index }}
                                    </option>
                                </select>
                            </div>
                            <div class="active-game-participants text-white">
                                <div class="participants-table">
                                    <table class="w-100">
                                        <thead>
                                            <tr>
                                                <th class="px-2 w-50">Participants</th>
                                                <th class="text-center px-2">Kills</th>
                                                <th class="text-center px-2">In game Rank</th>
                                                <th class="text-center px-2">Score</th>
                                                <th class="text-center px-2">Rank</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="px-2 participant-item"
                                                v-for="participantItem in bracket.final_phase[activeGrpIndex].games[currentGameIndex]"
                                                :key="participantItem.id">
                                                <td class="px-2 py-1 w-50">
                                                    <div class="d-flex align-items-center">
                                                        <div class="participant-avatar-container mr-2">
                                                            <img alt="" :src="avatarDir + participantItem.avatar"
                                                                @error.once="replaceImage" />
                                                        </div>
                                                        <h6 class="participant-name font-weight-normal m-0">
                                                            {{ participantItem.name }}</h6>
                                                    </div>
                                                </td>
                                                <td class="text-center px-2 py-1">{{ participantItem.kills }}</td>
                                                <td class="text-center px-2 py-1">{{ participantItem.in_game_rank ?
                                                    participantItem.in_game_rank : 'N/A' }}</td>
                                                <td class="text-center px-2 py-1">{{ participantItem.score }}</td>
                                                <td class="text-center px-2 py-1 ">
                                                    <p class="text-center m-0 mx-auto"
                                                        :class="[participantItem.rank <= 3 ? getRankClass(participantItem.rank) : '']">
                                                        {{
                                                            participantItem.rank ? (participantItem.rank > 3 ?
                                                                `${participantItem.rank} ème` : participantItem.rank) : 'N/A'
                                                        }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import moment from "moment/moment";
import grpList from "@/assets/utilisateurs-alt.svg"

export default {
    name: "LgameBattleRoyalBracket",
    data() {
        return {
            matchesLoaded: false,
            grpActive: null,
            grpSelected: false,
            currentGameIndex: 1,
            currentGame: null,
            isReportingScore: false,
            participantToReport: null,
            // new implementaion
            activeGrpIndex: null,
            TAB_GRP_OVERVIEW: "#tab_overview",
            TAB_GRP_GAMES: "#tab_grp_games",
            currentTab: "#tab_overview",
            grpListImg: grpList,
            TAB_GRP_PHASE: '#tab_grp_phase',
            TAB_FINAL_PHASE: '#tab_final_phase',
            currentPhaseTab: '#tab_grp_phase',
        }
    },
    computed: {
        isLoaded: {
            get() {
                return !!this.$store.state.bracket.currentBracket;
            }
        },
        currentUser: {
            get() {
                return this.$store.state.currentUser.user;
            }
        },
        participantType: {
            get() {
                if (this.isLoaded) {
                    return this.$store.state.currentTournament.participantType;
                }
            }
        },
        tournament_ended: {
            get() {
                if (this.isLoaded) {
                    return this.$store.state.currentTournament.tournamentItem.end_date
                        ? moment().isAfter(moment(this.$store.state.currentTournament.tournamentItem.end_date, 'DD-MM-YYYY'))
                        : true;
                }
            }
        },
        bracket: {
            get() {
                if (this.isLoaded) {
                    return this.$store.state.bracket.currentBracket.structure;
                }
            }
        },
        avatarDir: {
            get() {
                return this.participantType === 'player'
                    ? 'https://lgame.gg/uploads/players/avatars/'
                    : 'https://lgame.gg/uploads/teams/avatars/';
            }
        },
    },
    methods: {
        setActiveGrp(grpItem, grpIndex) {
            if (grpIndex !== null) {
                this.activeGrpIndex = grpIndex;
                this.grpSelected = true;
            } else {
                this.activeGrpIndex = null;
                this.grpSelected = false;
                this.currentGameIndex = 1;
            }
        },
        setCurrentGame() {
            this.currentGame = this.grpActive?.games[this.currentGameIndex];
        },
        setTab(tab, targetTab) {
            if (targetTab === 1) {
                this.currentPhaseTab = tab;
                this.activeGrpIndex = null;
                this.grpSelected = false;
                this.currentGameIndex = 1;
                this.currentTab = "#tab_overview";
            } else {
                this.currentTab = tab;
            }
        },
        getRankClass(rank) {
            let classItem = "";
            switch (rank) {
                case 1:
                    classItem = "rank-first";
                    break;
                case 2:
                    classItem = "rank-second";
                    break;
                case 3:
                    classItem = "rank-third";
                    break;
            }
            return classItem;
        },
        replaceImage(e) {
            e.target.src = require('@/assets/avatar-error-placeholder.png');
        },
    },
    created() {
    }
}
</script>

<style scoped>
.lgame-battle-royal-bracket-wrapper {
    background-color: transparent;
    height: 100%;
    overflow-y: auto;
}

.lgame-battle-royal-bracket-container {
    overflow-y: auto;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.group-item {
    background-color: rgba(0,0,0, 0.6);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}

.group-item:hover {
    cursor: pointer;
    background-color: rgba(0,0,0, 0.8);
    transform: translateX(2px);
}

.group-item:first-child {
    margin-left: 0;
}

.grp-number {
    font-family: 'sqwaredregular', sans-serif;
    font-weight: 700;
    color: white;
}

.participant-avatar-container {
    width: 24px;
    height: 24px;
    clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%, 0 4px);
}

.participant-avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.participant-name {
    color: white;
    font-family: 'sqwaredregular', sans-serif;
    font-weight: 300;
}

.participant-name:first-letter {
    text-transform: uppercase;
}

.participant-score {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.matches-manage-number-title {
    color: white;
    text-transform: uppercase;
    font-family: 'Roboto medium', sans-serif;
    letter-spacing: 1px;
    font-weight: 700;
}

.matches-number-title {
    color: #B4B4B4;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
}

.game-selector-label {
    color: white;
    font-family: "Roboto", sans-serif;
}

.game-selector {
    font-family: Roboto, 'sans-serif';
    border: unset;
    outline: unset;
    border-radius: 0;
    color: white;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 0);
    background-color: rgba(0,0,0, 0.4);
    text-align: center;
}

select:focus,
.settings-select:focus {
    outline: none !important;
    border: unset;
}

.group-list table {
    background-color: transparent;
    color: #E0E0E0;
}

.group-list tr:hover {
    background-color: transparent;
}

table {
    font-family: "Roboto", sans-serif;
    color: #AAAAAA;
    background-color: rgba(0,0,0, 0.67);
}

thead {
    color: white;
    height: 28px;
    border-bottom: 1px solid rgba(255,255,255,0.15);
}

tr {
    width: 100%;
    height: 32px;
}

th,
td {
    border-bottom: 1px inset rgba(255,255,255,0.15);
    font-family: "Roboto", sans-serif;
    color: white;
}

tr:last-child td {
    border-bottom: unset;
}

.participant-item:hover {
    background-color: var(--main-color);
    cursor: pointer;
}

.rank-first {
    color: white;
    font-family: 'sqwaredregular', sans-serif;
    font-weight: 900;
    background-color: rgba(255, 215, 0, 0.8);
    clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%, 0 4px);
    max-width: 100px;
}

.rank-first:after {
    content: "er";
    color: white;
    font-family: 'sqwaredregular', sans-serif;
    font-weight: 900;
}

.rank-second {
    color: white;
    font-weight: bold;
    background-color: rgba(192, 192, 192, 0.8);
    clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%, 0 4px);
    max-width: 100px;
}

.rank-third {
    color: white;
    font-weight: bold;
    background-color: rgba(204, 102, 51, 0.8);
    clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%, 0 4px);
    max-width: 100px;
}

.rank-second:after,
.rank-third:after {
    content: "ème";
    color: white;
    font-family: 'sqwaredregular', sans-serif;
    font-weight: 900;
}

.btn-back-grp-container {
    background-color: var(--main-color);
    clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
}

.btn-back-grp-container:hover {
    transform: translate(1px, -1px);
    cursor: pointer;
}

.btn-back-grp {
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'sqwaredregular', sans-serif;
    font-weight: 500;
    color: white;
}

.tabs-header-container {

}

.br-bracket-tab-btn {
    outline: none;
    border: none;
    font-family: 'sqwaredregular', sans-serif;
    color: white;
    font-weight: 300;
    background-color: rgba(0,0,0, 0.4);
}
.br-bracket-tab-btn:hover{
  background-color: var(--main-color);
}
.br-bracket-tab-btn:last-child {
    clip-path: polygon(0 0, calc(100% - 6px) 0, 100% 6px, 100% 100%, 0 100%);
}

.btn-active {
    font-weight: 400;
    border-bottom: 2px solid var(--main-color);
    background-color: rgba(0,0,0, 0.8);
    color: white;
}

.tabs-content-container {
    background-color: rgba(0,0,0, 0.2);
}</style>
<i18n>
{
  "en": {
    "group_phase": "Group phase bracket"
  }
}
</i18n>
