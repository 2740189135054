<template>
    <div class="stream-wrapper">
        <div class="stream-container">
            <div class="stream" ref="twitchPlayer"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "StreamComp",
    methods:{
        initPlayer(){
            // eslint-disable-next-line
            this.twitchPlayer = new Twitch.Player(this.$refs["twitchPlayer"], {
                width: '100%',
                height: '100%',
                channel: 'mgexesports',
            });
            this.twitchPlayer.setVolume(0.5);
            this.playerLoaded = true
            // eslint-disable-next-line
            this.twitchPlayer.addEventListener(Twitch.Player.ONLINE, () => {
                /*this.$store.dispatch('currentTournament/setTwitchLive', {
                    isLive: true,
                })*/
            });
            // eslint-disable-next-line
            this.twitchPlayer.addEventListener(Twitch.Player.ENDED, () => {

                /*this.$store.dispatch('currentTournament/setTwitchLive', {
                    isLive: false,
                })*/
            });
        },
    },
    mounted(){
        const twitchScript = document.createElement("script");
        twitchScript.type = "text/javascript";
        //twitchScript.setAttribute("crossorigin", "anonymous");
        //twitchScript.setAttribute("ref", "anonymous");
        twitchScript.setAttribute("src", "https://player.twitch.tv/js/embed/v1.js");
        document.head.appendChild(twitchScript);
        twitchScript.onload = () => {
            console.log("Twitch script loaded");
            this.initPlayer();
        }
    }
}
</script>
<style scoped>
.stream{
    height: 580px;
    background-color: var(--gray-bg-color);
}
@media screen and (max-width: 768px){
    .stream{
        width: 100% !important;
        height: 360px;
    }
}
</style>