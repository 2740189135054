<template>
    <div class="participate-card-wrapper p-5 w-100">
        <div>
            <h5 class="tagLine text-center text-white">{{tagLine}}</h5>
            <ReglementSetter class="text-center"/>
        </div>
        <div class="participate-btn-container" v-if="(!tournamentStarted && registrationStatus)"> <!-- (!tournamentStarted && registrationStatus)  -->
          <div v-if="!isAuthenticated">
            <button class="participate-btn px-5 py-3" data-toggle="modal" data-target="#loginModal" @click="participateAction()">
              {{$t('participate')}}
            </button>
          </div>
          <div v-else>
            <button class="participate-btn px-5 py-3" data-toggle="modal" data-target="#lgame-participation-pop-up" @click="participateAction()"
                    v-if="(!isParticipant)">
              {{$t('participate')}}
            </button>
            <button class="participate-btn px-5 py-3" data-toggle="modal" data-target="#lgame-participation-pop-up"
                    @click="cancelParticipateAction()" v-else>
              {{$t('cancelParticipate')}}
            </button>
          </div>
        </div>

    </div>
</template>
<script>
import ReglementSetter from '@/components/Partials/ReglementSetter.vue'
import 'moment';
import moment from 'moment';
export default {
    name: 'ParticipateCard',
    components: { ReglementSetter },
    props: ["tagLine", "tournamentId"],
    data(){
      return {

        participantsSize: 0,
        startDate: moment().add(7, 'days').valueOf(),
        today: moment().valueOf(),
        countDown:{
          days: null,
          hours: null,
          minutes: null,
          seconds: null,
        },
        diffTime: null,
        interval: 1000,
        duration: null,
        TYPE_PLAYER: 1,
        TYPE_TEAM: 2,

      }
    },
  computed:{
    isLoaded: {
      get() {
        return !!this.$store.state.currentTournament.tournamentItem;
      },
      set(val) {
        return val;
      }
    },
    isAuthenticated: {
      get() {
        return this.$store.state.currentUser.isAuthenticated;
      }
    },
    registrationStatus: { // true:  open, false: closed
      get() {
        if (this.isLoaded) {
          return this.$store.state.currentTournament.tournamentItem.settings.registration_status;
        }

      }
    },
    registrationEnds: {
      get() {
        if (this.isLoaded) {
          moment.locale('fr');
          const dateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
          return moment(dateTime, 'DD-MM-YYYY HH:mm').format('ddd Do, MMM  [à] hA');
        }
      }
    },
    isPastTournament: {
      get() {
        if (this.isLoaded) {
          const dateTime = this.$store.state.currentTournament.tournamentItem.end_date + ' ' + this.$store.state.currentTournament.tournamentItem.end_time;
          console.log(moment(dateTime, 'DD-MM-YYYY HH:mm'));
          return moment().isAfter(moment(dateTime, 'DD-MM-YYYY HH:mm'));
        }
      }
    },
    checkInStarted: {
      get() {
        if (this.isLoaded) {
          const startDateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
          const checkInStartsIn = this.$store.state.currentTournament.tournamentItem.settings.check_in_starts;
          const checkinStartTime = moment(startDateTime, 'DD-MM-YYYY HH:mm').subtract(checkInStartsIn, 'minutes').toDate();
          return moment().isBefore(moment(startDateTime, 'DD-MM-YYYY HH:mm')) && moment().isAfter(moment(checkinStartTime));
        }
      }
    },
    tournamentStarted: {
      get() {
        if (this.isLoaded) {
          const startDateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
          return moment().isSameOrAfter(moment(startDateTime, 'DD-MM-YYYY HH:mm'));
        }
      }
    },
    participantType: {
      get() {
        if (this.isLoaded) {
          const size = this.$store.state.currentTournament.tournamentItem.settings.play_mode.size;
          return size > 1 && size !== 0 ? this.TYPE_TEAM : this.TYPE_PLAYER;
        }
      }
    },
    tournamentSize: {
      get() {
        if (this.isLoaded) {
          return this.$store.state.currentTournament.tournamentItem.settings.size;
        }

      }
    },
    participantCount: {
      get() {
        if (this.isLoaded) {
          return this.participantType === this.TYPE_PLAYER ?
              this.$store.state.currentTournament.tournamentItem.participants_players.length :
              this.$store.state.currentTournament.tournamentItem.participants_teams.length;
        }
        return 0;
      }
    },
    isParticipant: {
      get() {
        if (this.isLoaded) {
          if (this.participantType === this.TYPE_PLAYER)
            return this.$store.state.currentTournament.playerIsParticipant;
          else
            return this.$store.state.currentTournament.playerTeamIsParticipant;
        }
        return false;
      }
    },
    isParticipantCheckedIn: {
      get(){
        if (this.isLoaded) {
          if (this.participantType === this.TYPE_PLAYER)
            return this.$store.state.currentTournament.playerIsCheckedIn;
          else
            return this.$store.state.currentTournament.playerTeamIsCheckedIn;
        }
      }
    },
    hasCustomFields: {
      get() {
        if (this.isLoaded) {
          const customFields = this.$store.state.currentTournament.tournamentItem.settings.custom_fields;
          return customFields !== null && customFields.length > 0;
        }
      }
    },
    isLoadingAction: {
      get() {
        if (this.isLoaded) {
          return this.$store.state.currentTournament.isLoadingAction;
        }
      }
    },
    teamParticipatedId: {
      get() {
        if (this.isLoaded) {
          return this.$store.state.currentTournament.teamParticipatedId;
        }
      }
    },
    isUserAuth: {
      get() {
        if (this.isLoaded) {
          return this.$store.state.currentUser.isAuthenticated;
        }
      }
    }
  },
  watch: {
    isUserAuth(newVal, oldVal) {
      if (oldVal === false && newVal === true && !this.isParticipant) {
        this.$store.dispatch('currentTournament/checkParticipation');
      }
    },
    startDate_comp(newVal, oldVal){
      if(oldVal !== newVal && newVal !== null){
        this.initCountdown();
      }
    }
  },
  methods: {
    participateAction() {
      console.log("participate btn clicked ...")
      // participate current auth user
      if (this.isAuthenticated) {
        this.$store.dispatch('currentTournament/triggerLoading', true);
        if (this.participantType === this.TYPE_TEAM) {
          const teamSize =  this.$store.state.currentTournament.tournamentItem.settings.play_mode.size;
          this.$store.dispatch('currentTournament/getCurrentPlayerTeams');
          this.$emit('init-pop-up', {
            type: 'teams',
            title: "Choisir une équipe",
            message: `Choisir une équipe parmis vous equipes qui a au moins ${teamSize} joueurs pour participer à cette tournoi:`,
            withOptions: false,
            popUpDispatch: 'currentTournament/setParticipantTeam',
          });
        }
        if (this.participantType === this.TYPE_PLAYER) {
          if (this.hasCustomFields) {
            this.$emit('init-pop-up', {
              type: 'custom',
              withOptions: true,
              title: "Formulaire d'inscription",
              message: 'Merci de remplire les champs suivants pour participer au tournoi',
              options: {
                mainAction: 'Participer',
                secondAction: 'Annuler'
              },
              popUpDispatch: 'currentTournament/setParticipant',
            });
          } else {
            this.$store.dispatch('currentTournament/setParticipant')
                .then(() => {
                  this.$store.dispatch('currentTournament/triggerLoading', false);
                });
          }
        }
      } else {
        this.$root.$emit('show-login', 'check_participation');
      }
    },
    cancelParticipateAction() {
      this.$store.dispatch('currentTournament/triggerLoading', true);
      if (this.participantType === this.TYPE_PLAYER) {
        console.log('cancelling player participation')
        this.$emit('init-pop-up', {
          type: 'warning',
          withOptions: true,
          title: 'Attention !',
          message: 'Etes-vous sur de vouloir annuler votre participation ?',
          popUpDispatch: 'currentTournament/cancelParticipation',
        });
        /*this.$store.dispatch('currentTournament/cancelParticipation')
            .then(() => {
                this.isLoadingAction = false;
            });*/
      }
      if (this.participantType === this.TYPE_TEAM) {
        this.$emit('init-pop-up', {
          type: 'warning',
          withOptions: true,
          title: 'Attention !',
          message: 'Etes-vous sur de vouloir annuler votre participation ?',
          popUpDispatch: 'currentTournament/cancelTeamParticipation'
        });
        console.log('cancelling team participation')
        /*this.$store.dispatch('currentTournament/cancelTeamParticipation')
            .then(() => {
                this.isLoadingAction = false;
            });*/
      }

    },
    checkPlayerIn() {
      this.$store.dispatch('currentTournament/checkPlayerIn');
    },
    checkParticipantIn() {
      this.$store.dispatch('currentTournament/triggerLoading', true);
      if (this.participantType === this.TYPE_TEAM) {
        this.$store.dispatch('currentTournament/checkTeamIn', {
          teamId: this.teamParticipatedId,
          checkinValue: true,
        }).then(() => {
          this.$store.dispatch('currentTournament/triggerLoading', false);
        });
      }
      if (this.participantType === this.TYPE_PLAYER) {
        this.$store.dispatch('currentTournament/checkPlayerIn', {
          checkinValue: true,
        })
            .then(() => {
              this.$store.dispatch('currentTournament/triggerLoading', false);
            });
      }
    },
    cancelCheckIn() {
      //only team case done
      this.$store.dispatch('currentTournament/triggerLoading', true);
      if (this.participantType === this.TYPE_PLAYER) {
        this.$emit('init-pop-up', {
          type: 'warning',
          withOptions: true,
          title: 'Attention !',
          message: 'Etes-vous sur de vouloir annuler votre checkin ?',
          popUpDispatch: 'currentTournament/checkPlayerIn',
          dispatchData: {
            checkinValue: false,
          }
        })
      }
      if (this.participantType === this.TYPE_TEAM) {
        this.$emit('init-pop-up', {
          type: 'warning',
          withOptions: true,
          title: 'Attention !',
          message: 'Etes-vous sur de vouloir annuler votre checkin ?',
          popUpDispatch: 'currentTournament/checkTeamIn',
          dispatchData: {
            teamId: this.teamParticipatedId,
            checkinValue: false,
          }
        });
      }
    },
    viewBracket() {
      this.$emit('view-bracket');
    },
    initCountdown(){
      this.diffTime = this.startDate_comp - this.today;
      this.duration = moment.duration(this.diffTime, 'milliseconds');
      setInterval(() => {
        this.duration = moment.duration(this.duration - this.interval, 'milliseconds');
        this.countDown.days = this.duration.days() < 10 ? '0' + this.duration.days() : this.duration.days();
        this.countDown.hours = this.duration.hours() < 10 ? '0' + this.duration.hours() : this.duration.hours();
        this.countDown.minutes = this.duration.minutes() < 10 ? '0' + this.duration.minutes() : this.duration.minutes();
        this.countDown.seconds = this.duration.seconds() < 10 ? '0' + this.duration.seconds() : this.duration.seconds();
        this.countdownInitialized = true;
      }, this.interval);
    }
  },
}
</script>
<style scoped>
.participate-card-wrapper {
    position: relative;
    clip-path: polygon(0 0, calc(100% - 18px) 0, 100% 18px, 100% 100%, 18px 100%, 0 calc(100% - 18px));
    min-height: 160px;
}
.tagLine{
    color: white;
    font-family: "sqwaredsemi_bold", sans-serif !important;
}
.participate-btn-container{
    position: absolute;
    bottom: 5px;
    right: 5px;
}
.participate-btn{
    outline: none;
    border: none;
    color: white;
    background-color: transparent;
    background-image: url("@/assets/imgs/participate-btn-bg.png");
    background-size: 100% 100%;
    text-transform: uppercase;
    font-family: "sqwaredsemi_bold", sans-serif;
}
.participate-btn:hover{
    transform:scale(1.04);
}

</style>