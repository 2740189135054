<template>
    <div class="footer-wrapper">
        <div class="mgex-footer-container container d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <div class="mgex-footer-content d-flex align-items-center justify-content-around justify-content-lg-between w-100">
                <div class="">
                    <img class="mgex-x-frmje-footer-image" src="@/assets/imgs/logos/mge-x-frmje.svg" height="86px" alt="">
                </div>
                <div class="social-networks-container d-none d-lg-flex flex-column align-items-center">
                    <div class="social-networks d-flex align-baseline">
                        <div class="facebook-container">
                            <a href="https://www.facebook.com/mgex.ma" target="_blank">
                                <img class="social-icon" src="@/assets/icons/facebook.svg" height="24px" alt="">
                            </a>
                        </div>
                        <div class="instagram-container mx-4">
                            <a href="https://www.instagram.com/mgex.ma/"  target="_blank">
                                <img class="social-icon" src="@/assets/icons/instagram.svg" height="24px" alt="">
                            </a>
                        </div>
                        <div class="x-container">
                            <a href="https://twitter.com/mgexma" target="_blank">
                                <img class="social-icon" src="@/assets/icons/x.svg" height="24px" alt="">
                            </a>
                        </div>
                        <div class="linkedin-container mx-4">
                            <a href="https://www.linkedin.com/company/102805036/" target="_blank">
                                <img class="social-icon" src="@/assets/icons/linkedin.svg" height="24px" alt="">
                            </a>
                        </div>
                        <div class="youtube-container">
                            <a href="https://www.youtube.com/channel/UCN-qYwRN2RABWRTenM1WTSg" target="_blank">
                                <img class="social-icon" src="@/assets/icons/youtube.svg" height="24px" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="website-container my-3">
                        <a href="https://moroccogamingexpo.ma" target="_blank">
                            <span class="light-part">www.</span>
                            <span class="bold-part">moroccogamingexpo</span>
                            <span class="light-part">.ma</span>
                        </a>
                    </div>
                </div>
                <div class="logo-ministre-container">
                    <img class="logo-ministere" src="@/assets/imgs/logos/ministere.svg" height="86px" alt="">
                </div>
            </div>
            <div class="social-networks-mob-container d-flex d-lg-none flex-column align-items-center mt-5">
                <div class="social-networks d-flex align-baseline">
                    <div class="facebook-container">
                        <a href="https://www.facebook.com/mgex.ma" target="_blank">
                            <img class="social-icon" src="@/assets/icons/facebook.svg" height="20px" alt="">
                        </a>
                    </div>
                    <div class="instagram-container mx-4">
                        <a href="https://www.instagram.com/mgex.ma/" target="_blank">
                            <img class="social-icon" src="@/assets/icons/instagram.svg" height="20px" alt="">
                        </a>
                    </div>
                    <div class="x-container">
                        <a href="https://twitter.com/mgexma" target="_blank">
                            <img class="social-icon" src="@/assets/icons/x.svg" height="20px" alt="">
                        </a>
                    </div>
                    <div class="linkedin-container mx-4">
                        <a href="https://www.linkedin.com/company/102805036/" target="_blank">
                            <img class="social-icon" src="@/assets/icons/linkedin.svg" height="20px" alt="">
                        </a>
                    </div>
                    <div class="youtube-container">
                        <a href="https://www.youtube.com/channel/UCN-qYwRN2RABWRTenM1WTSg" target="_blank">
                            <img class="social-icon" src="@/assets/icons/youtube.svg" height="20px" alt="">
                        </a>
                    </div>
                </div>
                <div class="website-container my-2">
                    <a href="https://moroccogamingexpo.ma" target="_blank">
                        <span class="light-part">www.</span>
                        <span class="bold-part">moroccogamingexpo</span>
                        <span class="light-part">.ma</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="powered-by-lgame w-100">
            <div class="copyright-container px-2">
                <p>MGEX © Copyright 2024 – <span v-html="$t('created_by')"></span></p>
            </div>
            <LgameFooter/>
        </div>
    </div>
</template>
<script>
import LgameFooter from '@/components/Footer/LgameFooter.vue'
export default {
    name: 'MGEXFooter',
    components: {
        LgameFooter,
    }
}
</script>
<style scoped>
.footer-wrapper{
    position: relative;
    height: 280px;
    background-image: url("@/assets/imgs/banners/footer-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: bottom right;
}
.powered-by-lgame{
    position: absolute;
    left: 0;
    bottom: 0;
}
.website-container a{
    text-decoration: none;
    color: white;
}
.light-part{
    font-family: "sqwaredthin", sans-serif !important;
    color: white;
    font-weight: 300;
}
.bold-part{
    font-family: "sqwaredsemi_bold", sans-serif !important;
}
.copyright-container{
    margin-bottom: -10px;
    font-size: 10pt !important;
}
.copyright-container p{
    color: white;
    font-family: "sqwaredthin", sans-serif !important;
    font-weight: 300 !important;

}
.copyright-container span a{
    text-decoration: none;
}

@media screen and (max-width: 768px){
    .footer-wrapper{
        background-size: auto 100%;
        background-position: right top;
    }
    .mgex-x-frmje-footer-image, .logo-ministere{
        height: 46px;
    }
    .website-container a{
        font-size: 12pt;
    }
    .copyright-container{
        margin-bottom: 30px !important;
        font-size: 7pt !important;
        text-align: center;
    }
}
</style>