<template>
    <div class="bracket-viewer-wrapper">
        <div class="arrow-decoration-container">
            <img src="@/assets/imgs/decoration-arrow.svg" width="80px" alt="">
        </div>
        <div class="bracket-viewer-header border-bottom border-white d-flex" v-if="hasHeader">
            <div class="header-item">
                <button class="bracket-header-btn px-lg-5 py-2"
                        :class="{'btn-active': current_tab === brackets_tabs.lol_tab}"
                        @click="setCurrentBracket(games.lol)">
                  League of legends
                </button>
            </div>
            <div class="header-item">
                <button class="bracket-header-btn px-lg-5 py-2"
                        :class="{'btn-active': current_tab === brackets_tabs.ea_fc_tab}"
                        @click="setCurrentBracket(games.ea_fc)">
                  EA FC 24
                </button>
            </div>
            <div class="header-item">
                <button class="bracket-header-btn px-lg-5 py-2"
                        :class="{'btn-active': current_tab === brackets_tabs.pubg_tab}"
                        @click="setCurrentBracket(games.pubg)">
                  PUBG Mobile
                </button>
            </div>
        </div>
        <div class="bracket-viewer-container pl-2 pt-2">
            <div class="bracket-mgex-signature p-2">
                <img src="@/assets/imgs/logos/mge-bracket-logo.svg" height="60px" alt="">
            </div>
            <div class="phases-header d-flex justify-content-start align-items-center">
                <button class="btn-phase-item py-1 px-2 px-lg-5"
                        :class="{'btn-phase-active': current_phase == bracket_phases.quali_1}"
                        @click="setCurrentPhase(bracket_phases.quali_1)">
                  Quali 1
                </button>
                <button class="btn-phase-item py-1 px-2 px-lg-5"
                        :class="{'btn-phase-active': current_phase == bracket_phases.quali_2}"
                        @click="setCurrentPhase(bracket_phases.quali_2)"
                >
                  Quali 2
                </button>
                <button class="btn-phase-item py-1 px-2 px-lg-5"
                        :class="{'btn-phase-active': current_phase == bracket_phases.finals}"
                        @click="setCurrentPhase(bracket_phases.finals)">
                  Finals
                </button>
            </div>
            <div class="bracket bracket-loading-container d-flex justify-content-center align-items-center h-100 w-100" v-if="bracket_loading">
              <LgameLoaderComp></LgameLoaderComp>
            </div>
            <div class="bracket h-100 w-100" v-else>
               <!-- <EliminationBracket v-if="tournamentLoaded && bracketHasStructure"></EliminationBracket> -->
                <div v-if="bracketType === bracket_types.ELIMINATION_BRACKET">
                  <EliminationBracket v-if="bracketStyle === bracket_styles.SINGLE_ELIMINATION"
                  ></EliminationBracket>
<!--                  <lgame-double-elimination-bracket
                      v-if="bracketStyle === bracket_styles.DOUBLE_ELIMINATION"
                      @init-toast="initToast"
                  ></lgame-double-elimination-bracket>-->
                </div>
                <div class="h-100 w-100" v-else-if="bracketType === bracket_types.BATTLE_ROYAL_BRACKET">
                  <LgameBattleRoyalBracket
                  ></LgameBattleRoyalBracket>
                </div>
                <div class="h-100 w-100" v-else-if="bracketType === bracket_types.ROUND_ROBIN_BRACKET">
                  <LgameRoundRobinBracket
                  ></LgameRoundRobinBracket>
                </div>

                <div class="bracket msg-container w-100 h-100 d-flex flex-column justify-content-center align-items-center h-100" v-else>
                    <h5>{{$t('bracket_empty')}}</h5>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EliminationBracket from "@/components/Brackets/EliminationBracket.vue";
import LgameBattleRoyalBracket from "@/components/Brackets/BattleRoyalBracket.vue";
import LgameLoaderComp from "@/components/Partials/LgameLoaderComp.vue";
import LgameRoundRobinBracket from "@/components/Brackets/LgameRoundRobinBracket.vue";
export default {
    name: "BracketViewer",
    components:{
      LgameRoundRobinBracket,
      LgameLoaderComp,
      LgameBattleRoyalBracket,
      EliminationBracket,
    },
    props: {
        hasHeader:{
            type:Boolean,
            default: false
        }
    },
    data(){
      return{
        bracket_types:{
          ELIMINATION_BRACKET: 'elimination_bracket',
          ROUND_ROBIN_BRACKET: 'round_robin_bracket',
          BATTLE_ROYAL_BRACKET: 'battle_royal_bracket',
        },
        bracket_styles:{
          SINGLE_ELIMINATION: 'single',
          DOUBLE_ELIMINATION: 'double',
        },
        brackets_tabs:{
          lol_tab: "#lol",
          pubg_tab: "#pubg",
          ea_fc_tab: "#ea_fc"
        },
        current_tab:  "#lol",
        bracket_loading: false,
        games:{
          lol: "lol",
          ea_fc: "ea_fc",
          pubg: 'pubg'
        },
        bracket_phases:{
          quali_1: 1,
          quali_2: 2,
          finals: 3,
        },
        current_phase: 1,
        games_id_by_phase:{
          1:{
            lol: process.env.VUE_APP_TOURNAMENT_LOL_QUALI1_ID,
            pubg: process.env.VUE_APP_TOURNAMENT_PUBG_QUALI1_ID,
            ea_fc: process.env.VUE_APP_TOURNAMENT_EAFC_QUALI1_ID,
          },
          2:{
            lol: process.env.VUE_APP_TOURNAMENT_LOL_QUALI2_ID,
            pubg: process.env.VUE_APP_TOURNAMENT_PUBG_QUALI2_ID,
            ea_fc: process.env.VUE_APP_TOURNAMENT_EAFC_QUALI2_ID,
          },
          3:{
            lol: process.env.VUE_APP_TOURNAMENT_LOL_FINALS_ID,
            pubg: process.env.VUE_APP_TOURNAMENT_PUBG_FINALS_ID,
            ea_fc: process.env.VUE_APP_TOURNAMENT_EAFC_FINALS_ID,
          }
        }
      }
    },
    computed:{
      isLoaded:{
        get(){
          return !!this.$store.state.bracket.currentBracket;
        }
      },
      tournamentLoaded:{
        get(){
          return !!this.$store.state.bracket.currentBracket;
        }
      },
      bracketHasStructure:{
        get(){
          return !!this.$store.state.bracket.currentBracket?.structure;
        }
      },
      bracketType:{
        get(){
          if(this.isLoaded){
            return this.$store.state.bracket.currentBracket ? this.$store.state.bracket.currentBracket.type : null;
          }
          return null;
        }
      },
      bracketStyle:{
        get(){
          if(this.isLoaded){
            return this.$store.state.bracket.currentBracket ? this.$store.state.bracket.currentBracket.style : null;
          }
          return null;
        }
      }
    },
    methods:{
        setCurrentBracket(game){
            this.bracket_loading = true;
            if(game === "lol"){
              this.current_tab = this.brackets_tabs.lol_tab;
              this.setCurrentBracketByPhase(this.current_phase, this.games.lol);
            }
            else if(game === "ea_fc"){
              this.current_tab = this.brackets_tabs.ea_fc_tab;
              this.setCurrentBracketByPhase(this.current_phase, this.games.ea_fc);

            }
            else if(game === "pubg"){
              this.current_tab = this.brackets_tabs.pubg_tab;
              this.setCurrentBracketByPhase(this.current_phase, this.games.pubg);

            }
            else{
              this.current_tab = this.brackets_tabs.lol_tab;
              this.setCurrentBracketByPhase(this.current_phase, this.games.lol);
            }
        },
        setCurrentPhase(phase) {
          this.bracket_loading = true;
          this.current_phase = phase;
          if (this.hasHeader) {
            switch (this.current_tab) {
              case this.brackets_tabs.lol_tab:
                console.log(`getting bracket for phase ${this.current_phase} and game ${this.games.lol}`)
                this.setCurrentBracketByPhase(this.current_phase, this.games.lol);
                break;
              case this.brackets_tabs.ea_fc_tab:
                this.setCurrentBracketByPhase(this.current_phase, this.games.ea_fc);
                break;
              case this.brackets_tabs.pubg_tab:
                this.setCurrentBracketByPhase(this.current_phase, this.games.pubg);
                break;
            }
          }else{
            const game = this.$route.name;
            console.log("game from router: ",game);
            this.getBracketFromRoute(this.current_phase)
          }
        },
        setCurrentBracketByPhase(phase, game){
          console.log(phase, game)
          this.$store.dispatch('bracket/fetchBracket', this.games_id_by_phase[phase][game]).finally(() => {
            this.bracket_loading = false;
          });
        },
        getBracketFromRoute(phase){
          const game = this.$route.name;
          console.log("current route: ", game);


          if(game === "lol"){
            this.setCurrentBracketByPhase(phase, this.games.lol);
          }
          else if(game === "eafc24"){
            this.setCurrentBracketByPhase(phase, this.games.ea_fc);
          }
          else if(game === "pubgmobile"){
            this.setCurrentBracketByPhase(phase, this.games.pubg);
          }
          else{
            this.setCurrentBracketByPhase(phase, this.games.lol);
          }
        }
    },
    mounted(){
      const game = this.$route.name;
      console.log("current route: ", game);
      if(game === "lol"){
        this.$store.dispatch('bracket/fetchBracket', process.env.VUE_APP_TOURNAMENT_LOL_QUALI1_ID);
      }
      else if(game === "eafc24"){
        this.$store.dispatch('bracket/fetchBracket', process.env.VUE_APP_TOURNAMENT_EAFC_QUALI1_ID);
      }
      else if(game === "pubgmobile"){
        this.$store.dispatch('bracket/fetchBracket', process.env.VUE_APP_TOURNAMENT_PUBG_QUALI1_ID);
      }
      else{
        this.$store.dispatch('bracket/fetchBracket', process.env.VUE_APP_TOURNAMENT_LOL_QUALI1_ID);
      }
    }
}
</script>
<style scoped>
.bracket-viewer-wrapper {
    position: relative;

    width: 100%;
}
.bracket-viewer-container{
    position: relative;
    background-color: var(--gray-bg-color);
    height: 100%;
    width: 100%;
     min-height: 600px;
}
.bracket-mgex-signature{
    position: absolute;
    bottom: 20px;
    right: 20px;
    opacity: 0.4;
}
.arrow-decoration-container{
    position: absolute;
    left:-12px;
    top:0;
    z-index: 1;
}
.bracket-viewer-header{
    background-color: #F7F7F7;
    width: fit-content;
    clip-path: polygon(20px 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%, 0 20px);
}
.bracket-header-btn{
    border: none;
    outline: none;
    background-color: transparent;
    color: black;
    font-family: "sqwaredsemi_bold", sans-serif;
}
.bracket-header-btn:hover{
    color: var(--main-color);
}
.btn-active{
    background-color: var(--gray-bg-color);
    clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 20px);
    color: var(--main-color)
}
.header-item:last-child .bracket-header-btn.btn-active{
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px),100% 100%, 20px 100%, 0 20px);
}
.msg-container h5{
    opacity: 0.8;
    font-family: "sqwaredsemi_bold", sans-serif;

}
.bracket-n-phases-container{
  height: 100%;
  overflow-y: hidden;
}
.btn-phase-item{
  outline: none;
  border: none;
  font-family: 'sqwaredregular', sans-serif;
  color: white;
  font-weight: 300;
  background-color: rgba(0,0,0, 0.4);
}
.btn-phase-item:hover{
  background-color: var(--main-color);
}
.btn-phase-item:last-child {
  clip-path: polygon(0 0, calc(100% - 6px) 0, 100% 6px, 100% 100%, 0 100%);
}
.btn-phase-active {
  font-weight: 400;
  border-bottom: 2px solid var(--main-color);
  background-color: rgba(0,0,0, 0.8);
  color: white;
}
.bracket{
  min-height: 560px;
  background-color: rgba(0,0,0, 0.1);
}
@media screen and (max-width: 768px)
{
    .bracket-viewer-wrapper {
        height: 420px;
    }
    .bracket-mgex-signature img{
        height: 56px;
    }
    .arrow-decoration-container{
        left:-8px
    }
    .arrow-decoration-container img{
        width:56px;
    }
    .msg-container h5{
        font-size: 12pt;
        text-align: center;
    }
    .bracket-viewer-container{
      min-height: 420px;
    }
  .bracket {
    min-height: 380px;
    height: 380px !important;
  }
}
</style>