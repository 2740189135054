<template>
    <div class="wrapper w-100">
        <div class="bg-red">
        </div>
        <div class="footer-powered-by" >
            <div class="img-container px-5 py-1" @click="goToLgame">
                <a @click="goToLgame">
                    <img src="@/assets/imgs/lgame/powered-by.png" height="18px" alt="Lgame"/>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: "PoweredByLgame",
    methods:{
        goToLgame(){
            window.location="https://lgame.gg/"
        }
    }
}
</script>
<style>
.wrapper{
    filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.4));
}
.footer-powered-by{
    position: relative;
    height: 16px;
    background-color: #031720;
    z-index:4;

}
.img-container:hover{
    cursor: pointer;
}
.bg-red{
    background-color: red;
    position: absolute;
    right:0;
    bottom: 0;
    height: 20px;
    width: 10%;
    z-index: 1;
}
.img-container{
    position: absolute;
    right: 6%;
    top:-20px;
    background-color: #031720;
    clip-path: polygon(8% 0%, 92% 0%, 100% 100%, 0% 100%);
}
</style>
