<template>
    <div class="timeline-slider-wrapper container">
        <div class="timeline-slider row px-2 px-lg-0">
            <TimeLineItem class="col-sm-12 col-lg-4 mb-2 mb-lg-0 px-2" v-for="(item, index) in items[page]" :key="index">
                <template #icon>
                    <img src="@/assets/icons/finals-icon.svg" height="24px" alt=""/>
                </template>
                <template #title>
                    <h5 class="item-title m-0">{{item.title}}</h5>
                </template>
                <template #desc>
                    <p class="timeline-desc text-left m-0 text-black">
                        {{item.desc}}
                    </p>
                </template>
                <template #date>
                    <p class="text-black timeline-date m-0">{{item.date}}</p>
                </template>
            </TimeLineItem>
        </div>
    </div>
</template>
<script>
import TimeLineItem from '@/components/TimeLine/TimeLineItem.vue'
export default {
    name: "TimeLineSlider",
    props:{
        game:{
            type: String,
            default: "",
        },
        page:{
            type: String,
            default: "home"
        }
    },
    data(){
        return {

        }
    },
  computed:{
      items:{
        get(){
          return {
            home:[
              {
                title: this.$t("timeLine.register.title"),
                desc: this.$t("timeLine.register.desc"),
                date: this.$t("timeLine.register.date"),
              },
              {
                title: this.$t("timeLine.qualify.title"),
                desc: this.$t("timeLine.qualify.desc"),
                date: this.$t("timeLine.qualify.date"),
              },
              {
                title: this.$t("timeLine.finals.title"),
                desc: this.$t("timeLine.finals.desc"),
                date: this.$t("timeLine.finals.date"),
              }
            ],
            pubg:[
              {
                title: this.$t("pubg.timeLine.quali1.title"),
                desc: this.$t("pubg.timeLine.quali1.desc"),
                date: this.$t("pubg.timeLine.quali1.date"),
              },
              {
                title: this.$t("pubg.timeLine.quali2.title"),
                desc: this.$t("pubg.timeLine.quali2.desc"),
                date: this.$t("pubg.timeLine.quali2.date"),
              },
              {
                title: this.$t("pubg.timeLine.bigFinal.title"),
                desc: this.$t("pubg.timeLine.bigFinal.desc"),
                date: this.$t("pubg.timeLine.bigFinal.date"),
              }
            ],
            ea_fc:[
              {
                title: this.$t("ea_fc_24.timeLine.quali1.title"),
                desc: this.$t("ea_fc_24.timeLine.quali1.desc"),
                date: this.$t("ea_fc_24.timeLine.quali1.date"),
              },
              {
                title: this.$t("ea_fc_24.timeLine.quali2.title"),
                desc: this.$t("ea_fc_24.timeLine.quali2.desc"),
                date: this.$t("ea_fc_24.timeLine.quali2.date"),
              },
              {
                title: this.$t("ea_fc_24.timeLine.bigFinal.title"),
                desc: this.$t("ea_fc_24.timeLine.bigFinal.desc"),
                date: this.$t("ea_fc_24.timeLine.bigFinal.date"),
              }
            ],
            lol:[
              {
                title: this.$t("leagueOfLegends.timeLine.quali1.title"),
                desc: this.$t("leagueOfLegends.timeLine.quali1.desc"),
                date: this.$t("leagueOfLegends.timeLine.quali1.date"),
              },
              {
                title: this.$t("leagueOfLegends.timeLine.quali2.title"),
                desc: this.$t("leagueOfLegends.timeLine.quali2.desc"),
                date: this.$t("leagueOfLegends.timeLine.quali2.date"),
              },
              {
                title: this.$t("leagueOfLegends.timeLine.bigFinal.title"),
                desc: this.$t("leagueOfLegends.timeLine.bigFinal.desc"),
                date: this.$t("leagueOfLegends.timeLine.bigFinal.date"),
              }
            ],
          }
        }
      }
  },
    components:{
        TimeLineItem
    }
}
</script>
<style scoped>
.item-title{
    color: var(--main-color);
    text-transform: uppercase;
    font-family: "StereoGothic-800" !important;
}
.timeline-desc{
    font-family: "sqwaredsemi_bold" !important;
}
.timeline-date{
    font-family: "sqwaredregular" !important;
}
@media screen and (max-width: 768px){
    .item-title{
        font-size: 14pt;
    }
}
</style>