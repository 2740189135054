<template>
    <div class="lang-selector-wrapper">
        <div class="lang-selector">
            <select class="lang-select pl-1 pr-4" v-model="selectedLang" @change="changeLang()">
                <!--<option value="ar">AR</option>-->
                <option value="en" selected default>EN</option>
                <option value="fr">FR</option>
            </select>
        </div>
    </div>
</template>
<script>
export default {
    name: "LangSelector",
    data(){
        return {
            selectedLang: "en",
        }
    },
    methods:{
        changeLang(){
            console.log("lang changed: ", this.selectedLang);
            this.$i18n.locale = this.selectedLang;
        }
    },
    mounted(){
        this.selectedLang = this.$i18n.locale;
    }
}
</script>
<style scoped>
.lang-selector-wrapper {

}
.lang-select{
    font-family: Roboto, 'sans-serif';
    height: 34px;
    border: unset;
    border-radius: 0;
    width: 100%;
    color: white;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
    background-color: black;
}
.lang-select:focus{
    outline: none;
}
.lang-select option:active{
    background-color: var(--main-color);
}
.lang-select option:hover{
    background-color: var(--main-color) ;
}
</style>