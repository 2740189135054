<template>
    <div class="lgame-round-robin-bracket-wrapper px-2 pt-2">
        <button  data-toggle="modal" data-target="#lgame-report-score-dialog" ref="triggerDialog" hidden></button>
        <div class="lgame-round-robin-bracket-container">
            <div class="tabs-header d-flex justify-content-start align-items-center">
                <div class="tab-item-title">
                    <button class="round-robin-tab-btn py-1 px-md-4"
                            :class="{'btn-active': currentTab === TAB_GROUP_STAGE}"
                        @click="setTab(TAB_GROUP_STAGE)">
                        Classement
                    </button>
                </div>
                <div class="tab-item-title" v-if="false">
                    <button class="round-robin-tab-btn py-1 px-md-4"
                            :class="{'btn-active': currentTab === TAB_MATCHES}"
                            @click="setTab(TAB_MATCHES)">
                        Matches de groupes
                    </button>
                </div>

                <div class="tab-item-title" v-if="playOffMode === bracket_styles.SINGLE_ELIMINATION && showPlayOff">
                    <button class="round-robin-tab-btn py-1 px-md-4"
                            :class="{'btn-active': currentTab === TAB_PLAY_OFF}"
                            @click="setTab(TAB_PLAY_OFF)">
                        Play-Off
                    </button>
                </div>
            </div>
            <div class="round-robin-tabs-container d-flex justify-content-center w-100">
                <div class="tab-item group-stage-tab w-100 p-2 px-md-4" v-show="currentTab === TAB_GROUP_STAGE">
                    <lgame-group-stage v-for="group in bracket.groups"
                                       :group="group"
                                       :key="'gr-'+group.group_id"
                    ></lgame-group-stage>
                   <!-- <div>
                        <h5 class="m-0">Qualifications: </h5>
                        <div class="ql-legend p-2">
                            <div class="d-flex justify-content-start align-items-center mb-2">
                                <div class="color-item qualified-color mr-2"></div>
                                <h6 class="m-0">Phase suivante</h6>
                            </div>
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="color-item possible-quali-color mr-2"></div>
                                <h6 class="m-0">Phase suivante possible</h6>
                            </div>
                        </div>
                    </div>-->
                </div>
                <div  class="tab-item tab-matches w-100" v-show="currentTab === TAB_MATCHES">
                    <div v-if="!matchesLoaded">Loading....</div>
                    <div class="d-flex justify-content-start align-items-center flex-wrap p-2 px-md-4 w-100 h-100" v-else>
                        <lgame-group-stage-match
                            v-for="(match, index) in matches"
                            :match="match"
                            :index="index+1"
                            :key="'m-gs-'+match.match_id"
                            @click.native="reportScore(match)"
                        >
                        </lgame-group-stage-match>
                    </div>
                </div>
                <div class="tab-item w-100" v-show="currentTab === TAB_PLAY_OFF" v-if="isPlayOffEnabled">
                    <lgame-elimination-bracket
                        :is-play-off="true"
                        v-if="playOffMode === bracket_styles.SINGLE_ELIMINATION && currentTab === TAB_PLAY_OFF "
                    ></lgame-elimination-bracket>
                    <!--<lgame-double-elimination-bracket
                        :is-play-off="true"
                        v-if="playOffMode === bracket_styles.DOUBLE_ELIMINATION && currentTab === TAB_PLAY_OFF"
                    ></lgame-double-elimination-bracket>-->
                </div>
            </div>
        </div>
       <!-- <lgame-report-score-dialog
            :can-call="canReportScore"
            @onClose="()=>{canReportScore=false}"
        >
        </lgame-report-score-dialog> -->
    </div>
</template>

<script>
    import LgameGroupStage from "./GroupStage/LgameGroupStage";
    import LgameEliminationBracket from './EliminationBracket.vue';
    //import LgameDoubleEliminationBracket from "./LgameDoubleEliminationBracket";
    import LgameGroupStageMatch from "./GroupStage/LgameGroupStageMatch";
    //import LgameReportScoreDialog from "../Common/LgameReportScoreDialog";
    import moment from "moment";
    export default {
        name: "lgame-round-robin-bracket",
        components:{
            LgameGroupStage,
            LgameEliminationBracket,
            //LgameDoubleEliminationBracket,
            LgameGroupStageMatch,
            //LgameReportScoreDialog
        },
        data(){
            return{
                TAB_GROUP_STAGE: '#group_stage',
                TAB_PLAY_OFF: '#play_off',
                TAB_MATCHES: '#matches',
                currentTab: '#group_stage',
                bracket_types:{
                    ELIMINATION_BRACKET: 'elimination_bracket',
                    ROUND_ROBIN_BRACKET: 'round_robin_bracket',
                },
                bracket_styles:{
                    SINGLE_ELIMINATION: 'single',
                    DOUBLE_ELIMINATION: 'double',
                },
                matchesLoaded: false,
                canReportScore: false,
            }
        },
        computed:{
            isLoaded:{
                get(){
                    return !!this.$store.state.bracket.currentBracket;
                }
            },
            bracketType:{
                get(){
                    if(this.isLoaded){
                        return this.$store.state.bracket.currentBracket.type;
                    }
                }
            },
            /*bracketStyle:{
                get(){
                    if(this.isLoaded){
                        return this.$store.state.currentTournament.tournamentItem.bracket.style;
                    }
                }
            },*/
            playOffMode:{
                get(){
                    if(this.isLoaded){
                        return this.$store.state.bracket.currentBracket.settings.play_off.mode;
                    }
                }
            },
            isPlayOffEnabled:{
                get(){
                    if(this.isLoaded){
                        return this.$store.state.bracket.currentBracket.settings.play_off.activated;
                    }
                }
            },
            bracket:{
                get(){
                    if(this.isLoaded){
                        const bracket = this.$store.state.bracket.currentBracket.structure;
                        bracket.groups.sort(this.sortByKey('group_name'));
                        return bracket;
                    }
                }
            },
            matches:{
                get(){
                    return this.$store.state.bracket.bracketMatches;
                }
            },
            currentUser:{
                get(){
                    return this.$store.state.currentUser.user;
                }
            },
            participantType:{
                get(){
                    if(this.isLoaded){
                        return this.$store.state.currentTournament.participantType;
                    }
                }
            },
            tournament_ended:{
                get(){
                    if(this.isLoaded){
                        return this.$store.state.currentTournament.tournamentItem.end_date
                            ? moment().isAfter(moment(this.$store.state.currentTournament.tournamentItem.end_date, 'DD-MM-YYYY'))
                            : true;
                    }
                }
            },
            organizerId:{
                get(){
                    return this.$store.state.currentTournament.tournamentItem.organizer.id;
                }
            },
          showPlayOff:{
              get(){
                return this.bracket?.play_off?.bracket?.length>0 || this.bracket?.play_off?.length>0;
              }
          }
        },
        methods:{
            setTab(tab){
                this.currentTab = tab;
            },
            reportScore(matchItem){
                console.log('from bracket match to report');
                console.log(matchItem);
                console.log('from bracket match to report');
                if(!this.tournament_ended){
                    if(this.bracketType === this.bracket_types.ROUND_ROBIN_BRACKET){
                        this.$store.dispatch('bracket/setRetrieveMatches', true);
                    }
                    if(Object.keys(this.currentUser).length > 0){
                        if(this.participantType === 'player'){
                            if(matchItem.participant.id === this.currentUser.player.id || matchItem.competitor.id === this.currentUser.player.id ||
                                (this.currentUser.organizer?.id === this.organizerId)){
                                console.log('passed check ');
                                this.$store.dispatch('bracket/setMatchToReport', {
                                    match: {
                                        match: {
                                            participant: matchItem.participant,
                                            competitor: matchItem.competitor,
                                        },
                                        match_id: matchItem.match_id,
                                        seed: matchItem.seed,
                                        match_round: matchItem.match_round
                                    },
                                    isPlayOff: this.isPlayOff,
                                }).then(()=>{
                                    /*this.$router.push({
                                        name: 'reportScore',
                                        params:{id: this.$store.state.currentTournament.tournamentItem.id
                                        }
                                    });*/
                                    this.canReportScore = true;
                                    this.$refs.triggerDialog.click();
                                });
                            }
                        }
                        if(this.participantType === 'team'){
                            if(this.canReportTeam(matchItem.participant.id, matchItem.competitor.id)
                                || (this.currentUser.organizer?.id === this.organizerId)){
                                this.$store.dispatch('bracket/setMatchToReport', {
                                    match: {
                                        match: {
                                            participant: matchItem.participant,
                                            competitor: matchItem.competitor,
                                        },
                                        match_id: matchItem.match_id,
                                        seed: matchItem.seed,
                                        match_round: matchItem.match_round
                                    },
                                    isPlayOff: this.isPlayOff,
                                }).then(()=>{
                                    this.canReportScore = true;
                                    this.$refs.triggerDialog.click();
                                });
                            }
                        }
                    }
                }

            },
            canReportTeam(participantId, competitorId){
                return this.$store.state.player.currentPlayer.plays_at.filter(team => {
                    if(team.id === participantId || team.id === competitorId){
                        return true;
                    }
                }).length > 0;
            },
            sortByKey(key){
                return function(a, b){
                    if(a[key] < b[key]) return -1;
                    else if(a[key] > b[key]) return 1;
                    return 0;
                }
            },
        },
        created(){
            this.$store.dispatch('bracket/getMatches').then(() => {
                this.matchesLoaded = true;
            });
        },
        activated(){
            this.$store.dispatch('bracket/getMatches').then(() => {
                this.matchesLoaded = true;
            });
            this.canReportScore = false;
        },
    }
</script>

<style scoped>
    .lgame-round-robin-bracket-container{
        height: 100%;
    }
    .tabs-header{
        width: fit-content;
        clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 0 100%);
        font-family: 'sqwaredregular', sans-serif;
        color: white;
        font-weight: 300;
        background-color: rgba(0,0,0, 0.4);
    }
    .round-robin-tab-btn{
        color: white;
        background-color: transparent;
        outline:none;
        border: none;
    }
    .round-robin-tab-btn:active{
        outline: none;
        border: none;
    }
    .round-robin-tab-btn:hover{
      background-color: var(--main-color);
    }
    .btn-active, .btn-active:hover{
      font-weight: 400;
      border-bottom: 2px solid var(--main-color);
      background-color: rgba(0,0,0, 0.8);
      color: white;
    }
    .round-robin-tabs-container{
        height: 80vh;
      background-color: rgba(0,0,0, 0.2);
    }
    .group-stage-tab{
        overflow-y: auto;
        height: 100%;
    }
    .tab-matches{
        overflow-y: auto;
        height: 100%;
    }
    .ql-legend{
        background-color: #010D11;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%);
        width: fit-content;
    }
    .color-item{
        width: 18px;
        height: 18px;
        clip-path: polygon(2px 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%,0 2px );
    }
    .qualified-color{
        background-color:#0CD949;
    }
    .possible-quali-color{
        background-color: #FF5300;
    }
    @media screen and (max-width: 376px){
        .tabs-header{
            clip-path: polygon(0 0, calc(100% - 6px) 0, 100% 6px, 100% 100%, 0 100%);
        }
        .round-robin-tab-btn{
            font-size: 13px;
            padding: 0 4px !important;
        }
    }
</style>
