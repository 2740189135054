<template>
    <div class="faq-item-wrapper mb-3 w-100" @click="expanded = !expanded">
        <div class="faq-item-container  p-4">
            <div class="question-container d-flex justify-content-between align-items-center w-100">
                <h5 class="question text-left m-0">{{ question }}</h5>
                <div class="arrow-container" :class="{'expanded': expanded}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" viewBox="0 0 44 23">
                        <path d="M22,31h0L0,8H7.652L22,23,36.348,8H44L22,31Z" transform="translate(0 -8)" fill="#FA4624FF"/>
                    </svg>

                </div>
            </div>
            <transition name="slide-fade">
                <div v-if="expanded">
                    <p class="answer px-2 text-justify mt-2" v-html="answer">

                    </p>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "faqItem",
    props:["question", "answer"],
    data(){
        return {
            expanded: false,
        }
    }
}
</script>

<style lang="css" scoped>
.slide-fade-enter-active {
    transition: all .2s ease;
}
.slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.15, 0.3, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
{
    transform: translateY(-10px);
    opacity: 0;
}
.faq-item-wrapper{
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 9px), calc(100% - 9px) 100%, 0 100%);
    padding: 1px;
    background-color: var(--main-color);
}
.faq-item-container{
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
}
.question{
    color: var(--main-color);
    font-family: "sqwaredsemi_bold" !important;

}

.arrow-container{
    transition: all .3s cubic-bezier(1.0, 0.15, 0.3, 1.0);
}
.expanded{
    transform: rotate(-180deg);
}
.answer{
    color: black;
    font-family: "sqwaredregular" !important;
}

</style>