<template>
    <div class="lgame-group-stage-wrapper d-flex justify-content-center align-items-center w-100 my-4">
        <div class="lgame-group-stage-container w-100">
            <div class="group-header pl-2 pr-5 py-2" :class="{'invert': invertColors}">
                <h5 class="group-name m-0">Groupe {{group.group_name}}</h5>
            </div>
            <div class="group-body w-100" :class="{'invert': invertColors}">
                <table class="w-100">
                    <thead :class="{'invert': invertColors}">
                        <tr>
                            <th class="px-2">Participants</th>
                            <th class="text-center px-2">MJ</th>
                            <th class="text-center px-2">G</th>
                            <th class="text-center px-2">P</th>
                            <th class="text-center px-2">N</th>
                            <th class="text-center px-2">BP</th>
                            <th class="text-center px-2">BC</th>
                            <th class="text-center px-2">DB</th>
                            <th class="text-center px-2">Pts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="participant-item px-2" v-for="(participant, index) in groupParticipants">
                            <td class="py-1" :class="{'invert': invertColors}">
                                <div class="participant-item-container d-flex justify-content-start align-items-center">
                                    <div class="qualification-marker"
                                         :class="{'qualified': (index === 0 || index===1 ) && participant.points > 0, 'possible-quali': (index===2 && participant.points > 0) }"
                                    ></div>
                                    <div class="mx-2">{{index+1}}</div>
                                    <div class="participant-avatar-wrapper">
                                        <div class="participant-avatar-container mr-2" :class="{'invert': invertColors}">
                                            <img alt=""
                                                 :src="avatarDir+participant.avatar"
                                                 width="100%" height="100%"
                                                 @error.once="replaceImage"
                                            />
                                        </div>
                                    </div>
                                    <h6 class="participant-name m-0">{{participant.name}}</h6>
                                </div>
                            </td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.match_played}}</td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.wins}}</td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.losses}}</td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.draw}}</td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.goals_for}}</td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.goals_against}}</td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.goals_diff}}</td>
                            <td class="text-center px-2 py-1" :class="{'invert': invertColors}">{{participant.points}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LgameGroupStage",
    props:{
        group:{
            type: Object,
            default: {},
        },
        isManaging:{
            type: Boolean,
            default: false,
        },
        invertColors:{
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
        }
    },

    computed:{
        participantType:{
            get(){
                const playModeSize = this.isManaging
                    ? this.$store.state.newTournament.tournament.settings.play_mode.size
                    : this.$store.state.currentTournament.tournamentItem.settings.play_mode.size;
                return playModeSize === 1 ? 'player' : 'team';
            }
        },
        avatarDir:{
            get(){
                return this.participantType === 'player'
                    ? process.env.MIX_STORAGE_PATH+'/'+process.env.MIX_PLAYERS_AVATARS_DIR
                    : process.env.MIX_STORAGE_PATH+'/'+process.env.MIX_TEAMS_AVATARS_DIR;
            }
        },
        groupParticipants:{
            get(){
                return this.sortParticipants();
            },
            set(val){
                return val;
            }
        }
    },
    methods:{
        sortParticipants(){
            return this.group.participants.sort(this.sortByPoints('points')); // was sorting by quali_index
        },
        sortByPoints(key){
            return function(a, b){
                if(a[key] < b[key]) return 1;
                else if (a[key] === b[key]) { return a['quali_index'] < b['quali_index'] ?  1 :  -1;}
                else if(a[key] > b[key]) return -1;
                return 0;
            }
        },
        replaceImage(e){
          e.target.src = require('@/assets/avatar-error-placeholder.png');
        }
    }
}
</script>

<style scoped>
    .lgame-group-stage-container{
       /* filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.4));*/
    }
    .group-header{
        clip-path: polygon(0 0, calc(100% - 6px) 0, 100% 6px, 100% 100%, 0 100%);
        background-color: rgba(0,0,0, 0.4);
        width:fit-content;
        max-width:160px;
    }
    .group-body{
        background-color: rgba(0,0,0, 0.4);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
        overflow: auto;
    }
    .group-name{
        color: White;
        font-size: 12pt;
        line-height: 100%;
        font-family: 'sqwaredregular', sans-serif;
        font-weight: 700;
    }
    .participant-avatar-wrapper{
        /*filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4));*/
    }
    .participant-avatar-container{
        background-color: rgba(0,0,0, 0.4);
        width: 30px;
        height: 28px;
        clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
    }
    table{
        color: white;
    }
    thead{
        height: 28px;
        background-color: rgba(0,0,0, 0.4);
    }
    tr{
        width: 100%;
        height: 32px;
    }

    td{
        background-color: rgba(0,0,0, 0.4);
      border-bottom: 1px solid rgba(255,255,255,0.2);
    }
    tr:last-child td{
        border-bottom: unset;
    }
    .qualification-marker{
        height: 30px;
        width: 3px;
        background-color: transparent;
    }
    .qualified{
        background-color: #0CD949;
    }
    .possible-quali{
        background-color: #FF5300;
    }
    .invert{
        background-color: #00171F !important;
        border-color: #010D11 !important;
    }
    .participant-avatar-container.invert{
        background-color: #2E3A3E !important;
    }

</style>
