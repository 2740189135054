<template>
    <div class="card-wrapper">
        <div class="card-container color-black">
           <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "GameCard",
    props: {
        bg:{
            type: String,
            default: "",
        },
        link:{
            type: String,
            default: "",
        }
    },
}
</script>
<style scoped>
.card-container:hover{
    transform: scale(1.01);
    cursor: pointer;
}
</style>