const reglement_fr = "https://drive.google.com/file/d/1mwpR1Khz0Uq3JKEyaWtGCPHgbA1AJ-0t/view?usp=sharing";
const reglement_an = "https://drive.google.com/file/d/1ZfOVA0ErdkwvsN9I1GC9wxu36Td5P0Za/view?usp=sharing";
export default {
    en: {
        reglement: "Terms and conditions",
        navbar: {
            home: 'Home',
            league_of_legends: 'League Of Legends',
            ea_fc_24: 'EA FC 24',
            pubg_mobile: 'PUBG Mobile',
            watch: 'Watch',
            register: 'Register',
            log_in: 'Log In'
        },
        hero_section: {
            tournamentTitle: `MGEX ESPORT<br>Tournament`,
            tournamentDate: 'From 24 to 26 May 2024',
            discover: 'Discover'
        },
        about_title: 'About',
        about: 'The Ministry of Youth, Culture, and Communication is hosting the inaugural Morocco Gaming Expo 2024 in Rabat. From May 24th to May 26th, 2024, this event will feature esports tournaments in three different  games: EA FC 24, PUBG MOBILE, and League Of Legends, with a total cash prize of 390,000 Moroccan Dirhams!',
        cash_prize: 'Cash Prize',
        in_total: 'In total',
        place: 'place',
        totalCashPrizeTitle: 'A total cash prize of',
        totalValue: "390 000 Dhs",
        totalCashPrizeSubTitle: 'will be distributed as follows',
        bigQuestion: 'DO YOU HAVE WHAT IT TAKES TO WIN ?',
        getReady: 'Prepare yourself and take your chances for a shot at winning the prize!',
        timeLine: {
            title: 'timeline',
            register: {
                title: 'Register',
                desc: 'Register for one of the Morocco Gaming Expo tournament qualifiers!',
                date: 'From 27 April to 10 May 2024'
            },
            qualify: {
                title: 'qualify',
                desc: 'Take part in the online qualifiers and try to win a place in the grand final!',
                date: 'From 4 to 15 May 2024'
            },
            finals: {
                title: 'BIG FINAL',
                desc: 'Play on the largest esports stage ever seen in Morocco, in front of a captivating and enthusiastic audience.',
                date: '24, 25 and 26 May 2024'
            }
        },
        participate: 'participate',
        cancelParticipate: "cancel participation",
        stream: 'stream',
        brackets: 'Brackets',
        bracket_empty: "The results will be ready once the tournament started",
        faq: {
            criterias: {
                question: 'What are the criteria for taking part in the tournaments?',
                answer: 'Participating in the tournaments is simple! You only need to have been a resident in Morocco for a minimum of 6 months, be at least 16 years old, sign up for the tournaments, and adhere to the general rules of each competition.'
            },
            tournamentRules: {
                question: 'Where can I consult the tournament rules?',
                answer: `You can find the full tournaments rules by clicking on the following <a href="${reglement_an}" target="_blank">link</a>, please read these rules carefully and abide by them, no complaints will be accepted for violations.`
            },
            accountCreationLgame: {
                question: 'How can I set up an account on Lgame.gg or register with my team?',
                answer: `- Registering on <a href="https://lgame.gg" target="_blank">Lgame.gg</a>? It\'s so easy! Simply follow these <a href="https://www.youtube.com/watch?v=15q-SAFSBVI" target="_blank">steps</a> to create your Lgame.gg account.<br>- To set up a team, ensure all members have Lgame.gg accounts first.Then, the captain can create the team by following these <a href="https://www.youtube.com/watch?v=O-0hvV8WwUY" target="_blank">instructions</a>.`
            },
            tournamentFinalsPlace: {
                question: 'Where will the tournament final be held?',
                answer: 'The grand final of the tournament will take place at MOROCCO GAMING EXPO, which will be held from 24 to 26 May 2024 at the Sofitel Jardin des Roses in Rabat.'
            }
        },
        discordBanner: {
            question: 'Need help ?',
            answer: `Stuck in registration? Not sure about eligibility requirements? Join the server to get help<br>from our Tournament Administration team.`
        },
        created_by: `website created by <a style="color: white; text-decoration:none;" href="https://geekmaroc.agency/" target="_blank">GEEK Maroc Agency</a>`,
        leagueOfLegends: {
            about: 'Embark on your Road to Morocco Gaming Expo Final today by registering for the League of Legends Tournament! Compete against players for your shot at glory, fame, and incredible prizes.',
            participateTagLine:
                'Don’t delay – secure your spot now and kickstart your journey to victory!',
            in_total_cash_prize: '150 000 Dhs in total',
            total_value: '150 000 Dhs',
            prizes_values: {
                first: '90 000 dhs',
                second: '40 000 Dhs',
                third: '20 000 Dhs'
            },
            first: '1st',
            second: '2nd',
            third: '3rd',
            place: 'place',
            timeLine: {
                title: 'timeline',
                quali1: {
                    title: 'Qualification 1',
                    desc: '2 Teams will be qualified to the grand final',
                    date: 'From 4 May to 7 May 2024'
                },
                quali2: {
                    title: 'Qualification 2',
                    desc: '2 Teams will be qualified to the grand final',
                    date: 'From 11  May to 14 May 2024'
                },
                bigFinal: {
                    title: 'Big Final',
                    desc: '4 teams will be competing for the title',
                    date: '25 and 26 May 2024'
                }
            }
        },
        ea_fc_24: {
            about: 'Participate in the EA FC 24 Tournament – the premier virtual football competition! Take on players from across Morocco for a chance to achieve glory, fame, and incredible prizes.',
            participateTagLine: 'Don’t wait any longer – register now and kick\n off the excitement!',
            disclaimer: 'This tournament is not affiliated with or sponsored by Electronic Arts Inc. or its licensors.',
            in_total_cash_prize: '80 000 Dhs in total',
            total_value: '80 000 Dhs',
            prizes_values: {
                first: '45 000 dhs',
                second: '25 000 Dhs',
                third: '10 000 Dhs',
            },
            first: '1st',
            second: '2nd',
            third: '3rd',
            place: 'place',
            timeLine: {
                title: 'timeline',
                quali1: {
                    title: 'Qualification 1',
                    desc: '16 players will be qualified to the grand final',
                    date: 'From 4 May to 6 May 2024'
                },
                quali2: {
                    title: 'Qualification 2',
                    desc: '16 players will be qualified to the grand final',
                    date: 'From 11  May to 13 May 2024'
                },
                bigFinal: {
                    title: 'Big Final',
                    desc: '32 players will be competing for the title',
                    date: '25 and 26 May 2024'
                }
            }
        },
        pubg: {
            about: 'Get ready for the PUBG Mobile Tournament – where the battle royale action is at its peak! Challenge players from all over Morocco for a chance to claim glory, recognition, and fantastic rewards.',
            participateTagLine: 'Don’t miss out – register now and dive into the fray!',
            in_total_cash_prize: '160 000 Dhs in total',
            total_value: '160 000 Dhs',
            prizes_values: {
                first: '60 000 dhs',
                second: '36 000 Dhs',
                third: '28 000 Dhs',
                fourth: '28 000 Dhs',
                fifth: '12 000 Dhs',
                sixth: '8 000 Dhs',
            },
            first: '1st',
            second: '2nd',
            third: '3rd',
            place: 'place',
            fourth: '4th',
            fifth: '5th',
            sixth: '6th',
            timeLine: {
                title: 'timeline',
                quali1: {
                    title: 'Qualification 1',
                    desc: '6 Teams will be qualified to the grand final',
                    date: 'From 4 May to 8 May 2024'
                },
                quali2: {
                    title: 'Qualification 2',
                    desc: '6 teams will be qualified to the grand final',
                    date: 'From 11  May to 15 May 2024'
                },
                bigFinal: {
                    title: 'Big Final',
                    desc: '12 teams will be competing for the title',
                    date: '24 May 2024'
                }
            }
        },
        /*participatePopUp:{
            choose_team: "Choose a team",
            select_team_msg: "Choose one of your teams having at least {size} players to take part in this tournament:",
            participate_this_team: "participate with this team",
            close: "Close",
            extra_infos: "Additional information",
            extra_infos_msg: "Please provide the following information to complete your team's registration",
            fields:{
                whatsapp_number: "Numéro Whatsapp",
                gender: "Gender",
                region: "Region",
                age: "Age"
            },
            participate:"Participate",
            cancel: "Cancel"
        },
        userCard:{
            dashboard: "Dashboard",
            organize: "organize",
            my_tournaments: "My tournaments",
            my_participations: "My participations",
            profile_settings: "Profile settings",
            log_out: "Log out"
        },
        login:{
            login: "LOG IN",
            account_verification_title: "Lgame.gg account verification",
            account_verification_msg: "An e-mail will be sent to you to verify your account.",
            close: "Close",
            required_field: "required field"
        },*/
    },
    fr: {
       /* participatePopUp: {
            choose_team: "Choisir une équipe",
            select_team_msg: "Choisir une équipe qui a au minimum {size} joueurs parmis vous équipes pour participer a cette tournoi:",
            participate_this_team: "Participer avec cette équipe",
            close: "Fermer",
            extra_infos: "Informations supplémentaire",
            extra_infos_msg: "Veuillez fournir les informations suivantes pour compléter l'inscription de votre équipe",
            fields:{
                whatsapp_number: "Numéro Whatsapp",
                gender: "Genre",
                region: "Région",
                age: "Age"
            },
            participate:"Participer",
            cancel: "Annuler"
        },
        userCard:{
          dashboard: "Tableau de bord",
          organize: "organiser",
          my_tournaments: "Mes tournois",
          my_participations: "Mes participations",
          profile_settings: "Paramètres du profil",
          log_out: "Se déconnecter"
        },
        login:{
            login: "SE CONNECTER",
            account_verification_title: "Vérification du compte Lgame.gg",
            account_verification_msg: "Un mail vous sera envoyé pour vérifier votre compte.",
            close: "Fermer",
            required_field: "Champ requis"
        },*/
        reglement: "Règlement du tournoi ",
        navbar: {
            home: 'Acceuil',
            league_of_legends: 'League Of Legends',
            ea_fc_24: 'EA FC 24',
            pubg_mobile: 'PUBG Mobile',
            watch: 'Stream',
            register: 'S\'inscrire',
            log_in: 'Se connecter'
        },
        hero_section: {
            tournamentTitle: `Tournoi Esport<br>MGEX`,
            tournamentDate: 'Du 24 au 26 mai 2024',
            discover: 'Découvrir'
        },
        about_title: 'À propos',
        about: 'Le ministère de la Jeunesse, de la Culture et de la Communication organise la première édition du Morocco Gaming Expo 2024 à Rabat. Du 24 au 26 mai 2024, cet événement proposera des tournois esports dans trois jeux différents : EA FC 24, PUBG MOBILE, et League Of Legends, avec un prix total de 390 000 dirhams marocains !',
        cash_prize: 'Cash Prize',
        place: 'place',
        in_total: 'Au total',
        totalCashPrizeTitle: 'Un Cash Prize en espèces d\'un montant total de',
        totalValue: "390 000 Dhs",
        totalCashPrizeSubTitle: 'sera réparti comme suit',
        bigQuestion: 'AVEZ-VOUS CE QU\'IL FAUT POUR GAGNER ?',
        getReady: 'Préparez-vous et tentez votre chance pour remporter le prix !',
        timeLine: {
            title: 'calendrier',
            register: {
                title: 'S\'inscrire',
                desc: 'Inscrivez-vous à l\'un des tournois qualificatifs du Morocco Gaming Expo !',
                date: 'Du 27 avril au 10 mai 2024'
            },
            qualify: {
                title: 'Se Qualifier',
                desc: 'Participez aux qualifications en ligne et tentez de gagner une place pour la grande finale !',
                date: 'Du 4 au 15 mai 2024'
            },
            finals: {
                title: 'GRANDE FINALE',
                desc: 'Jouez sur la plus grande scène esports jamais vue au Maroc, devant un public captivé et enthousiaste.',
                date: '24, 25 et 26 mai 2024'
            }
        },
        participate: 'Participer',
        cancelParticipate: "Anuuler la participation",
        stream: 'Stream',
        brackets: 'Brackets',
        bracket_empty: "Les résultats seront disponibles dès le début des tournois",
        faq: {
            criterias: {
                question: 'Quels sont les critères de participation aux tournois ?',
                answer: 'Participer aux tournois est simple ! Il suffit d\'être résident au Maroc depuis au moins 6 mois, d\'avoir au moins 16 ans, de s\'inscrire aux tournois et de respecter les règles générales de chaque compétition.'
            },
            tournamentRules: {
                question: 'Où puis-je consulter le règlement du tournoi ?',
                answer: `Vous pouvez trouver les règles complètes du tournois en cliquant sur le <a href="${reglement_fr}" target="_blank">lien</a> suivant, veuillez lire attentivement ces règles et les respecter, aucune plainte ne sera acceptée en cas d\'infraction.`
            },
            accountCreationLgame: {
                question: 'Comment puis-je créer un compte sur Lgame.gg ou m\'inscrire avec mon équipe ?',
                answer: `- S\'inscrire sur <a href="https://lgame.gg" target="_blank">Lgame.gg</a> ? C\'est très simple ! Il vous suffit de suivre les <a href="https://www.youtube.com/watch?v=15q-SAFSBVI" target="_blank">étapes suivantes</a> pour créer votre compte Lgame.gg.<br>- Pour créer une équipe, assurez-vous d\'abord que tous les membres ont un compte Lgame.gg. Ensuite, le capitaine peut créer l\'équipe en suivant ces <a href="https://www.youtube.com/watch?v=O-0hvV8WwUY" target="_blank">instructions</a>.`
            },
            tournamentFinalsPlace: {
                question: 'Où se déroulera la finale du tournoi ?',
                answer: 'La grande finale du tournoi se déroulera au salon MOROCCO GAMING EXPO qui se tiendra du 24 au 26 mai 2024 au Sofitel Jardin des Roses à Rabat.'
            }
        },
        discordBanner: {
            question: 'Besoin d\'aide ?',
            answer: `Vous êtes bloqué dans votre inscription ? Vous n'êtes pas sûr des critères d'éligibilité ? <br>Rejoignez le serveur pour obtenir l'aide de notre équipe d'administration des tournois.`
        },
        created_by: `site web créé par <a style="color: white; text-decoration:none;" href="https://geekmaroc.agency/" target="_blank">GEEK Maroc Agency</a>`,
        leagueOfLegends: {
            about: 'Démarrez dès maintenant votre parcours vers la finale du Morocco Gaming Expo en vous inscrivant au tournoi League of Legends ! Affrontez d\'autres joueurs pour avoir une chance de remporter la gloire, la renommée et des prix incroyables.',
            participateTagLine:
                'Ne perdez pas de temps - assurez-vous de réserver votre place dès maintenant et lancez votre parcours vers la victoire !',
            in_total_cash_prize: '150 000 Dhs au total',
            prizes_values: {
                first: '90 000 Dhs',
                second: '40 000 Dhs',
                third: '20 000 Dhs'
            },
            first: '1ère',
            second: '2ème',
            third: '3ème',
            place: 'place',
            timeLine: {
                title: 'calendrier',
                quali1: {
                    title: 'Qualification 1',
                    desc: '2 équipes seront qualifiées pour la grande finale',
                    date: 'Du 4 mai au 7 mai 2024'
                },
                quali2: {
                    title: 'Qualification 2',
                    desc: '2 équipes seront qualifiées pour la grande finale',
                    date: 'Du 11 mai au 14 mai 2024'
                },
                bigFinal: {
                    title: 'Grande finale',
                    desc: '4 équipes seront en compétition pour le titre',
                    date: '25 et 26 mai 2024'
                }
            }
        },
        ea_fc_24: {
            about: 'Participez au tournoi EA FC 24, la compétition de football virtuel ! Affrontez des joueurs de tout le Maroc pour avoir une chance d\'atteindre la gloire, la renommée et des prix incroyables.',
            participateTagLine: 'N\'attendez pas plus longtemps - inscrivez-vous dès maintenant et donnez le coup d\'envoi !',
            disclaimer: 'Ce tournoi n\'est pas affilié ou sponsorisé par Electronic Arts Inc. ou ses concédants de licence.',
            in_total_cash_prize: '80 000 Dhs au total',
            prizes_values: {
                first: '45 000 Dhs',
                second: '25 000 Dhs',
                third: '10 000 Dhs',
            },
            first: '1ère',
            second: '2ème',
            third: '3ème',
            place: 'place',
            timeLine: {
                title: 'Calendrier',
                quali1: {
                    title: 'Qualification 1',
                    desc: '16 joueurs seront qualifiés pour la grande finale',
                    date: 'Du 4 mai au 6 mai 2024'
                },
                quali2: {
                    title: 'Qualification 2',
                    desc: '16 joueurs seront qualifiés pour la grande finale',
                    date: 'Du 11 mai au 13 mai 2024'
                },
                bigFinal: {
                    title: 'Grande finale',
                    desc: '32 joueurs se disputeront le titre',
                    date: '25 et 26 mai 2024'
                }
            }
        },
        pubg: {
            about: 'Préparez-vous pour le tournoi PUBG Mobile, où l\'action est à son apogée ! Défiez des joueurs de tout le Maroc pour avoir une chance de remporter la gloire, la reconnaissance et des récompenses fantastiques.',
            participateTagLine: 'Ne manquez pas cette occasion - inscrivez-vous dès maintenant et plongez dans la compétition !',
            in_total_cash_prize: '160 000 Dhs au total',
            prizes_values: {
                first: '60 000 Dhs',
                second: '36 000 Dhs',
                third: '28 000 Dhs',
                fourth: '28 000 Dhs',
                fifth: '12 000 Dhs',
                sixth: '8 000 Dhs',
            },
            first: '1ère',
            second: '2ème',
            third: '3ème',
            place: 'place',
            fourth: '4ème',
            fifth: '5ème',
            sixth: '6ème',
            timeLine: {
                title: 'Calendrier',
                quali1: {
                    title: 'Qualification 1',
                    desc: '6 équipes seront qualifiées pour la grande finale',
                    date: 'Du 4 mai au 8 mai 2024'
                },
                quali2: {
                    title: 'Qualification 2',
                    desc: '6 équipes seront qualifiées pour la grande finale',
                    date: 'Du 11 mai au 15 mai 2024'
                },
                bigFinal: {
                    title: 'Grande finale',
                    desc: '12 équipes se disputeront le titre',
                    date: '24 mai 2024'
                }
            }
        }
    },
    /*ar: {
        reglement: 'شروط وأحكام المسابقة',
        navbar: {
            home: 'الرئيسية',
            league_of_legends: 'League Of Legends',
            ea_fc_24: 'EA FC 24',
            pubg_mobile: 'PUBG Mobile',
            watch: 'Stream',
            register: 'التسجيل',
            log_in: 'تسجيل الدخول'
        },
        hero_section: {
            tournamentTitle: 'بطولات الرياضة الإلكترونية<br>MGEX',
            tournamentDate: 'من 24 إلى 26 ماي 2024',
            discover: 'اكتشف'
        },
        about_title: 'حول الفعالية',
        about: 'تنظم وزارة الشباب والثقافة والتواصل الدورة الافتتاحية لمعرض Morocco Gaming Expo 2024 في الرباط. في الفترة من 24 ماي إلى 26 ماي 2024، سيضم هذا الحدث بطولات الرياضات الإلكترونية في ثلاث ألعاب مختلفة: EA FC 24، و PUBG MOBILE، و League Of Legends، بإجمالي جوائز نقدية تبلغ 390,000 درهم !',
        cash_prize: 'الجوائز النقدية',
        place: 'المركز',
        in_total: 'في المجموع',
        totalCashPrizeTitle: 'مجموع الجوائز النقدية',
        totalValue:  '390،000 درهم',
        totalCashPrizeSubTitle: 'سيتم توزيعها على النحو التالي',
        bigQuestion: 'هل لديك ما يلزم للفوز؟',
        getReady: 'جهز نفسك واغتنم فرصتك للفوز بالجائزة!',
        timeLine: {
            register: {
                title: 'التسجيل',
                desc: 'Morocco Gaming Expo سجِّل في إحدى تصفيات بطولة معرض ',
                date: 'من 27 أبريل إلى 10 ماي 2024'
            },
            qualify: {
                title: 'التصفيات',
                desc: '!شارك في التصفيات عند بعد وحاول الفوز بمكان في النهائي الكبير',
                date: 'من 4 إلى 15 ماي 2024'
            },
            finals: {
                title: 'النهائي الكبير',
                desc: 'العب على أكبر مسرح للرياضات الإلكترونية في المغرب على الإطلاق، أمام جمهور ومتحمس',
                date: ' من 24 إلى 26 ماي 2024'
            }
        },
        participate: 'شارك',
        stream: 'Stream',
        brackets: 'Brackets',
        faq: {
            criterias: {
                question: 'ما هي معايير المشاركة في البطولات؟',
                answer: '.المشاركة في البطولات جد سهلة! ما عليك سوى أن تكون مقيمًا في المغرب لمدة 6 أشهر على الأقل، أن يكون عمرك لا يقل عن 16 عامًا ، أن تسجل في البطولات، وأن تلتزم بالقواعد العامة لكل مسابقة'
            },
            tournamentRules: {
                question: 'أين يمكنني الاطلاع على قواعد البطولة؟',
                answer: '.يمكنك العثور على القواعد الكاملة للبطولات بالضغط على الرابط التالي، يرجى قراءة هذه القواعد بعناية والالتزام بها'
            },
            accountCreationLgame: {
                question: 'كيف يمكنني إنشاء حساب على Lgame.gg أو التسجيل مع فريقي؟?',
                answer: '- هل تريد التسجيل في Lgame.gg؟ الأمر في غاية السهولة! ما عليك سوى اتباع هذه الخطوات لإنشاء حساب Lgame.gg الخاص بك. <br>- لإعداد فريق، تأكد من أن جميع الأعضاء لديهم حسابات Lgame.gg أولاً، ثم يمكن للكابتن إنشاء الفريق باتباع هذه التعليمات.'
            },
            tournamentFinalsPlace: {
                question: 'أين سيقام نهائي البطولة؟',
                answer: 'سيُقام النهائي الكبير للبطولة في معرض MOROCCO GAMING EXPO، الذي سيُقام في الفترة من 24 إلى 26 مايو 2024 في فندق سوفيتيل جاردان دي روز في الرباط.'
            }
        },
        discordBanner: {
            question: 'هل تحتاج إلى مساعدة؟',
            answer: `.هل لديك مشكلة ما؟ لست متأكدًا من متطلبات الأهلية؟ انضم إلى ديسكورد للحصول على المساعدة من فريق إدارة البطولة`
        },
        created_by: `website created by <a style="color: white; text-decoration:none;" href="https://geekmaroc.agency/" target="_blank">GEEK Maroc Agency</a>`,
        leagueOfLegends: {
            about: 'طريقك نحو نهائي معرض المغرب للألعاب الإلكترونية يبدأ الآن! انضم إلى بطولة League of Legends وتحدَّى اللاعبين لتفوز بالمجد، والجوائز الرائعة!',
            participateTagLine:
                'لا تتأخر - احجز مكانك الآن وابدأ رحلتك نحو النصر!',
            in_total_cash_prize: 'إجمالي 150,000 درهم.',
            prizes_values: {
                first: '90،000 درهم',
                second: '40،000 درهم',
                third: '20،000 درهم'
            },
            first: 'أول',
            second: 'ثاني',
            third: 'ثالث',
            place: 'مركز',
            timeLine: {
                title: 'الجدول الزمني',
                quali1: {
                    title: 'التصفيات 1',
                    desc: '2 فريقان سيتأهلان إلى النهائي الكبير',
                    date: 'من 4 ماي إلى 7 ماي 2024'
                },
                quali2: {
                    title: 'التصفيات 2',
                    desc: '2 فريقان سيتأهلان إلى النهائي الكبير',
                    date: 'من 11 ماي إلى 14 ماي 2024'
                },
                bigFinal: {
                    title: 'النهائي الكبير',
                    desc: '4 فرق ستتنافس على اللقب',
                    date: '25 و26 ماي 2024'
                }
            }
        },
        ea_fc_24: {
            about: 'انضم إلى تحديات EA FC 24 - البطولة الافتراضية لكرة القدم! تحدَّى لاعبين من جميع أنحاء المغرب لفرصة لتحقيق المجد والفوز بجوائز مذهلة.',
            participateTagLine: 'لا تنتظر أكثر من ذلك - سجل الآن واستمتع بالإثارة!',
            disclaimer: 'هذه البطولة ليست تابعة أو برعاية شركة Electronic Arts Inc. أو الجهات المرخصة لها.',
            in_total_cash_prize: 'إجمالي 80,000 درهم.',
            prizes_values: {
                first: '45،000 درهم',
                second: '25،000 درهم',
                third: '10،000 درهم',
            },
            first: 'المركز',
            second: 'الثاني',
            third: 'الثالث',
            place: 'المركز',
            timeLine: {
                title: 'الجدول الزمني',
                quali1: {
                    title: 'التصفيت 1',
                    desc: 'سيتأهل 16 لاعباً إلى النهائي الكبير',
                    date: 'من 4 ماي إلى 6 ماي 2024'
                },
                quali2: {
                    title: 'التصفيت 2',
                    desc: 'سيتأهل 16 لاعباً إلى النهائي الكبير',
                    date: 'من 11 ماي إلى 13 ماي 2024'
                },
                bigFinal: {
                    title: 'النهائي الكبير',
                    desc: 'سيتنافس 32 لاعباً على اللقب',
                    date: '25 و26 ماي 2024'
                }
            }
        },
        pubg: {
            about: 'هيا، انضموا إلى رحلة الإثارة في PUBG Mobile - حيث تبلغ المعارك ذروتها! تحدّوا اللاعبين من جميع أنحاء المغرب واستعدوا للفوز بالمجد، التقدير، والجوائز الرائعة.',
            participateTagLine: 'لا تفوّت الفرصة - سجّل الآن وانطلق في المعركة!',
            in_total_cash_prize: 'إجمالي 160,000 درهم.',
            prizes_values: {
                first: '60،000 درهم',
                second: '36،000 درهم',
                third: '28،000 درهم',
                fourth: '16،000 درهم',
                fifth: '12،000 درهم',
                sixth: '8،000 درهم',
            },
            first: 'الأول',
            second: 'الثاني',
            third: 'الثالث',
            place: 'المركز',
            fourth: 'الرابع',
            fifth: 'الخامس',
            sixth: 'السادس',
            timeLine: {
                title: 'الجدول الزمني',
                quali1: {
                    title: 'التصفيت 1',
                    desc: 'ستتأهل 6 فرق إلى النهائي الكبير',
                    date: 'From 4 May to 8 May 2024'
                },
                quali2: {
                    title: 'التصفيت 2',
                    desc: 'ستتأهل 6 فرق إلى النهائي الكبير',
                    date: 'من 11 ماي إلى 15 ماي 2024'
                },
                bigFinal: {
                    title: 'النهائي الكبير',
                    desc: '12 فريقاً سيتنافسون على اللقب',
                    date: '24 ماي 2024'
                }
            }
        }
    }*/
}
