<template>
    <div class="regelement-container my-2">
        <a class="reglement-link px-4" :href="getRegelement()" target="_blank">{{$t('reglement')}}</a>
    </div>
</template>
<script>
export default {
    name: 'ReglementSetter',
    computed:{
        selectedLang:{
            get(){
                return this.$i18n.locale;
            }
        }
    },
    methods:{
        /*
        Réglement AR : https://drive.google.com/file/d/11b4KFr0ek__WlY6LTI95XuLoNrvE6_SG/view?usp=sharing
        Réglement FR : https://drive.google.com/file/d/1jdfFB9mAecB-Q3PlTrjIUpNjiLmsBx9Q/view?usp=sharing
        Réglement EN : https://drive.google.com/file/d/19BjHtPV0XxAmNAbujM9e7tmcpSrhnRr3/view?usp=sharing


        NEW VER 04/05/2024 FR : https://drive.google.com/file/d/1mwpR1Khz0Uq3JKEyaWtGCPHgbA1AJ-0t/view?usp=sharing
        NEW VER 04/05/2024 EN : https://drive.google.com/file/d/1ZfOVA0ErdkwvsN9I1GC9wxu36Td5P0Za/view?usp=sharing

        Création compte : https://www.youtube.com/watch?v=15q-SAFSBVI
        Création team : https://www.youtube.com/watch?v=O-0hvV8WwUY
         */
        getRegelement(){
            switch(this.selectedLang){
                case "fr":
                    return "https://drive.google.com/file/d/1mwpR1Khz0Uq3JKEyaWtGCPHgbA1AJ-0t/view?usp=sharing";
                case "en":
                    return "https://drive.google.com/file/d/1ZfOVA0ErdkwvsN9I1GC9wxu36Td5P0Za/view?usp=sharing";
                default:
                    return "https://drive.google.com/file/d/1mwpR1Khz0Uq3JKEyaWtGCPHgbA1AJ-0t/view?usp=sharing";
            }
        }
    }
}
</script>
<style scoped>
.reglement-link{
    color: white;
    font-family: "sqwaredregular" !important;
    font-size: 10pt;
}
</style>