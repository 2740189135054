<script>
import {RouterView} from 'vue-router';
import DiscordContact from '@/components/Cards/Dsicord/DiscordContact.vue';
import MGEXFooter from '@/components/Footer/MGEXFooter.vue';
import FaqList from "@/components/FAQ/FaqList.vue"; //'./components/FAQ/FAQList.vue';//
import axios from 'axios';

export default {
      components: {
        RouterView,
        DiscordContact,
        MGEXFooter,
        FaqList
      },
      watch:{
        /*$route (to, from){
          console.log("switch", from, to)
          if(to.name === "eafc24"){
            console.log("switched to ea fc ")
            this.$store.dispatch('currentTournament/getTournament', 138);
          }
          if(to.name === "pubgmobile" ){
            console.log("switched to pubgmobile ")
            this.$store.dispatch('currentTournament/getTournament', 139);
          }
          if(to.name === "lol" ){
            console.log("switched to lol ")
            this.$store.dispatch('currentTournament/getTournament', 140);
          }

        }*/
      },
      methods: {

      },
      created(){
        console.log("vue app prod value: ", process.env.VUE_APP_PROD);
        const apiToken = process.env.VUE_APP_PROD == true ? process.env.VUE_APP_API_TOKEN : process.env.VUE_APP_API_LOCAL_TOKEN ;
        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
        axios.defaults.baseURL = process.env.VUE_APP_PROD == "true" ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_API_URL;
        console.log("base url: ", process.env.VUE_APP_PROD == "true" ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_API_URL);
        this.$store.dispatch('currentUser/authCheck').then(response => {
          if (response.success) {
            this.$store.dispatch('currentUser/setCurrentUser');
          }
        });
        console.log("token: ", process.env.VUE_APP_API_TOKEN)

        //this.$store.dispatch('currentTournament/getTournament', 144);
      },
      mounted() {
        console.log("token: ", process.env.VUE_APP_API_TOKEN)
        const resizeOps = () => {
          document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
          this.isMobile = window.innerWidth <= 768 ? true : false;
        };

        resizeOps();
        window.addEventListener("resize", resizeOps);

      }
    }

</script>

<template>
    <div>
        <RouterView />
        <div class="container my-5">
            <FaqList />
        </div>
        <div class="container my-5">
            <DiscordContact />
        </div>
        <div class="w-100 ">
            <MGEXFooter />
        </div>
    </div>
</template>

<style scoped></style>
