import { render, staticRenderFns } from "./ParticipateCard.vue?vue&type=template&id=6fcb4bde&scoped=true&"
import script from "./ParticipateCard.vue?vue&type=script&lang=js&"
export * from "./ParticipateCard.vue?vue&type=script&lang=js&"
import style0 from "./ParticipateCard.vue?vue&type=style&index=0&id=6fcb4bde&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fcb4bde",
  null
  
)

export default component.exports