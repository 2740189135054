<template>
    <div>
        <NavBar />
        <div class="hero-section-wrapper position-relative">
            <div
                class="hero-section d-flex justify-content-center justify-content-lg-start align-items-center container pt-lg-5">
                <div class="hero-section-content pt-lg-2">
                    <div class="mge-x-frmje-container">
                        <img src="@/assets/imgs/logos/mge-x-frmje.svg" height="120px" alt="">
                    </div>
                    <div class="tournament-title-container my-5">
                        <h1 class="tournament-title" v-html="$t('hero_section.tournamentTitle')"></h1>
                        <h6 class="tournament-date">{{$t("hero_section.tournamentDate")}}</h6>
                    </div>
                    <div class="my-5">
                        <div class="game-logo my-4">
                            <img src="@/assets/imgs/logos/ea-fc-white.svg" height="48px" alt="">
                        </div>
                        <div class="hero-section-btn-container">
                          <ParticipateButton  @init-pop-up="initPopUp"/>
                        </div>
                        <div>
                            <ReglementSetter />
                        </div>
                    </div>

                </div>
            </div>
            <div class="logo-ministere-container">
                <img class="ministere-logo" src="@/assets/imgs/logos/ministere.svg" height="86px" alt="">
            </div>
            <div class="disclaimer">
                <p class="m-0">{{$t('ea_fc_24.disclaimer')}}</p>
            </div>
        </div>
        <div class="about-container container my-5">
            <p class="text-center about-desc">{{$t('ea_fc_24.about')}}</p>
        </div>
        <div class="about-container position-relative container my-5 w-100"
            style="background-image:url('@/assets/imgs/ea-participate-bg.png')"
        >
            <ParticipateCard class="participate-card" :tag-line="$t('ea_fc_24.participateTagLine')" @init-pop-up="initPopUp" />
            <div class="perso-container">
                <img class="perso" :src="require('@/assets/imgs/ea-perso.webp')" height="160px" />
            </div>
        </div>
        <div class="container-fluid p-0 my-5">
            <TournamentPrize game="ea" />
        </div>
        <div class="container mt-5">
          <h2 class="section-title" dir="auto">{{$t("timeLine.title")}}</h2>
        </div>
        <div class="timeline d-flex flex-column justify-content-center mb-5">
            <TimeLineSlider game="eafc24" page="ea_fc" />
        </div>
        <div class="stream container my-5" id="stream">
            <h2 class="section-title" dir="auto">{{$t("stream")}}</h2>
            <StreamComp />
        </div>
        <div class="bracket container my-5" id="bracket">
            <h2 class="section-title" dir="auto">{{$t("brackets")}}</h2>
            <BracketViewer />
        </div>
      <!-- Lgame participate pop up-->
      <LgameParticipatePopUp :pop-up-type="popUpOptions.type" :title="popUpOptions.title"
                             :pop-up-message-body="popUpOptions.message" :with-options="popUpOptions.withOptions"
                             :options="popUpOptions.options" v-if="showPopUp" @modal-closed="abortAction"
                             @acceptAction="acceptAction" @abortAction="abortAction"></LgameParticipatePopUp>
    </div>
</template>
<script>
import NavBar from '@/components/Nav/NavBar.vue'
import BracketViewer from '@/components/Brackets/BracketViewer.vue'
import TimeLineSlider from '@/components/TimeLine/TimeLineSlider.vue'
import StreamComp from '@/components/Stream/StreamComp.vue'
import ParticipateCard from '@/components/Cards/ParticipateCard.vue'
import TournamentPrize from '@/components/Prize/TournamentPrize.vue'
import ReglementSetter from '@/components/Partials/ReglementSetter.vue'
import LgameParticipatePopUp from "@/components/LgameParticipatePopUp.vue";
import ParticipateButton from "@/components/Buttons/ParticipateButton.vue";

export default {
    name: "EAFC24View",
    components: {
      ParticipateButton,
      ReglementSetter, TournamentPrize, StreamComp, TimeLineSlider, BracketViewer, NavBar,
      ParticipateCard,
      LgameParticipatePopUp
    },
    data(){
        return {
          showPopUp: false,
          popUpOptions: {
            type: '',
            title: '',
            message: '',
          },
            items:{
                home:[
                    {
                        title: this.$t("timeLine.register.title"),
                        desc: this.$t("timeLine.register.desc"),
                        date: this.$t("timeLine.register.date"),
                    },
                    {
                        title: this.$t("timeLine.qualify.title"),
                        desc: this.$t("timeLine.qualify.desc"),
                        date: this.$t("timeLine.qualify.date"),
                    },
                    {
                        title: this.$t("timeLine.finals.title"),
                        desc: this.$t("timeLine.finals.desc"),
                        date: this.$t("timeLine.finals.date"),
                    }
                ],
                pubg:[
                    {
                        title: this.$t("pubg.timeLine.quali1.title"),
                        desc: this.$t("pubg.timeLine.quali1.desc"),
                        date: this.$t("pubg.timeLine.quali1.date"),
                    },
                    {
                        title: this.$t("pubg.timeLine.quali2.title"),
                        desc: this.$t("pubg.timeLine.quali2.desc"),
                        date: this.$t("pubg.timeLine.quali2.date"),
                    },
                    {
                        title: this.$t("pubg.timeLine.finals.title"),
                        desc: this.$t("pubg.timeLine.finals.desc"),
                        date: this.$t("pubg.timeLine.finals.date"),
                    }
                ],
                ea_fc:[
                    {
                        title: this.$t("ea_fc_24.timeLine.quali1.title"),
                        desc: this.$t("ea_fc_24.timeLine.quali1.desc"),
                        date: this.$t("ea_fc_24.timeLine.quali1.date"),
                    },
                    {
                        title: this.$t("ea_fc_24.timeLine.quali2.title"),
                        desc: this.$t("ea_fc_24.timeLine.quali2.desc"),
                        date: this.$t("ea_fc_24.timeLine.quali2.date"),
                    },
                    {
                        title: this.$t("ea_fc_24.timeLine.finals.title"),
                        desc: this.$t("ea_fc_24.timeLine.finals.desc"),
                        date: this.$t("ea_fc_24.timeLine.finals.date"),
                    }
                ],
                lol:[
                    {
                        title: this.$t("leagueOfLegends.timeLine.quali1.title"),
                        desc: this.$t("leagueOfLegends.timeLine.quali1.desc"),
                        date: this.$t("leagueOfLegends.timeLine.quali1.date"),
                    },
                    {
                        title: this.$t("leagueOfLegends.timeLine.quali2.title"),
                        desc: this.$t("leagueOfLegends.timeLine.quali2.desc"),
                        date: this.$t("leagueOfLegends.timeLine.quali2.date"),
                    },
                    {
                        title: this.$t("leagueOfLegends.timeLine.finals.title"),
                        desc: this.$t("leagueOfLegends.timeLine.finals.desc"),
                        date: this.$t("leagueOfLegends.timeLine.finals.date"),
                    }
                ],
            }
        }
    },
    methods:{
      acceptAction(payload) {
        this.showPopUp = false;

        if (payload && payload.hasCustomFields) {
          if (payload.teamId) {
            this.$store.dispatch('currentTournament/setParticipantTeam', {
              teamId: payload.teamId,
              customFieldsValue: payload.customFieldsValues,
            }).then(() => {
              this.$store.dispatch('currentTournament/triggerLoading', false);
            });
          }
          else {
            this.$store.dispatch(this.popUpOptions.popUpDispatch, {
              customFieldsValue: payload.customFieldsValues,
            }).then(() => {
              this.$store.dispatch('currentTournament/triggerLoading', false);
            });
          }
        } else {
          if (payload && payload.teamId) {
            this.$store.dispatch('currentTournament/setParticipantTeam', {
              teamId: payload.teamId
            }).then(() => {
              this.$store.dispatch('currentTournament/triggerLoading', false);
            });
          }
          else {
            console.log('payload from pop up action ================= ')
            console.log(payload)
            console.log('payload from pop up action ================= ')
            if (this.popUpOptions.dispatchData) {
              this.$store.dispatch(this.popUpOptions.popUpDispatch, this.popUpOptions.dispatchData)
                  .then(() => {
                    this.$store.dispatch('currentTournament/triggerLoading', false);
                  });
            } else {
              this.$store.dispatch(this.popUpOptions.popUpDispatch).then(() => {
                this.$store.dispatch('currentTournament/triggerLoading', false);
              });
            }
          }
        }
      },
      abortAction() {
        this.showPopUp = false;
        this.$store.dispatch('currentTournament/triggerLoading', false);
      },
      initPopUp(options) {
        this.popUpOptions = options;
        this.showPopUp = true;
      },
    },
  created(){
    this.$store.dispatch('currentUser/authCheck').then(response => {
      if (response.success) {
        this.$store.dispatch('currentUser/setCurrentUser');
      }
    });
    // EA FC quali 1
    this.$store.dispatch('currentTournament/getTournament', process.env.VUE_APP_TOURNAMENT_EAFC_QUALI2_ID);
  },
  mounted() {

    this.$store.dispatch('currentTournament/getTournament', process.env.VUE_APP_TOURNAMENT_EAFC_QUALI2_ID);
    console.log("token: ", process.env.VUE_APP_API_TOKEN)
    const resizeOps = () => {
      document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
      this.isMobile = window.innerWidth <= 768 ? true : false;
    };

    resizeOps();
    window.addEventListener("resize", resizeOps);
  },

}
</script>
<style scoped>
.hero-section-wrapper{
    background-color: var(--gray-bg-color);
    background-image: url("@/assets/imgs/eafc-hero-bg.png");
    background-size: 100% 100%;
    background-position: right bottom;
}
.hero-section{
    min-height: calc(var(--vh, 1vh) * 100);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 16px;
}
.tournament-title{
    color: white;
    font-family: "sqwaredblack" !important;
    text-transform: uppercase;
}
.disclaimer{
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.disclaimer p{
    color: white;
    opacity: 0.4;
    font-family: "sqwaredregular" !important;
    font-size: 10pt;
}
.tournament-date{
    color: white;
    font-family: "sqwaredregular" !important;
}
.hero-section-btn {
    background-color: var(--main-color);
    outline: none;
    border: none;
    color: white;
    font-family: "sqwaredblack" !important;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
}
.hero-section-btn:hover{
    transform: scale(1.01);
    background-color: #ff3f1a;
}
.about-desc{
    color: black;
    font-family: "sqwaredsemi_bold";
}
.section-title{
    color: var(--main-color);
    font-family: "sqwaredblack" !important;
    text-transform: uppercase;
}
.timeline{
    background-image: url('@/assets/imgs/timeline-bg.webp');
    background-size: 100% 100%;
    background-position: center center;
    min-height: 400px;
}
.big-question{
    color: black;
    font-family: "Sqwaredblack", sans-serif;
}
.get-ready{
    color: black;
    font-family: "sqwaredsemi_bold" !important;
}
.logo-ministere-container{
    position: absolute;
    bottom: 32px;
    right: 32px;
}
.v-banner-item:hover{
    transform: scale(1.01);
    cursor: pointer;
}
.participate-card{
    background-image: url("@/assets/imgs/ea-participate-bg.png");
  background-position: right bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.perso-container{
    position: absolute;
    left:-60px;
    bottom:0;
}
@media screen and (max-width: 768px) {
    .hero-section{

    }
    .hero-section-wrapper {
        background-size: auto 100%;
        background-position: left bottom;
    }
    .section-title {
        font-size: 18pt;
    }
    .tournament-title{
      font-size: 26pt;
    }
    .mge-x-frmje-container img{
        height: 80px;
    }
    .hero-section-content{
        text-align: center;
    }
    .logo-ministere-container {
        bottom: 50px;
    }
    .logo-ministere-container img{
        height: 48px;
    }

    .disclaimer p{
        font-size: 7pt;
    }
    .game-logo img{
        height: 56px;
    }
  .tournament-title{
    font-size: 26pt;
  }
}
@media screen and (max-height: 580px){
  .hero-section{
    min-height: 700px;
  }
}
</style>