<template>
    <div class="card-list row">
        <GameCard class="col-sm-12 col-lg-4"  @click.native="goTo('lol')">
            <img src="@/assets/imgs/cards/lol_card.svg" width="100%" alt=""/>
        </GameCard>
        <GameCard class="col-sm-12 col-lg-4 my-2 my-lg-0"  @click.native="goTo('eafc24')">
            <img src="@/assets/imgs/cards/ea_fc_card.svg" width="100%" alt=""/>
        </GameCard>
        <GameCard class="col-sm-12 col-lg-4 " @click.native="goTo('pubg')">
            <img src="@/assets/imgs/cards/pubg_card.svg" width="100%" alt=""/>
        </GameCard>
    </div>
</template>
<script>
import GameCard from '@/components/Cards/GameCard.vue'
export default {
    name: 'CardList',
    components:{
        GameCard,
    },
    data(){
        return {
            games:{
                lol:{
                    bg: '@/assets/imgs/cards/lol_card.svg',
                    participateLink: "",
                },
                pubg:{
                    bg: "@/assets/imgs/cards/pubg_card.svg",
                    participateLink: "",
                },
                ea_fc:{
                    bg: "@/assets/imgs/cards/ea_fc_card.svg",
                    participateLink: "",
                }
            }
        }
    },
    methods:{
        goTo(game){
            console.log("gamee => ", game);
            switch (game){
                case 'eafc24':
                    this.$router.push('/eafc24');
                    break;
                case 'lol':
                    this.$router.push('/lol');
                    break;
                case 'pubg':
                    this.$router.push('/pubgmobile');
                    break;
                default:
                    this.$router.push('/')
            }
        }
    }
}
</script>