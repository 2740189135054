<template>
    <div class="timeLine-item-wrapper">
        <div class="arrow-decoration">
            <img src="@/assets/imgs/timeline-deco-arrow.svg" alt="">
        </div>
        <div class="timeline-item-container p-2">
            <div class="timeline-item-content d-flex flex-column justify-content-between h-100">
                <div class="item-header d-flex align-items-center">
                    <div class="mr-2">
                        <slot name="icon"></slot>
                    </div>
                    <slot name="title"></slot>
                </div>
                <div class="item-body my-2 p-2">
                    <slot name="desc"></slot>
                </div>
                <div class="item-footer d-flex align-items-center">
                    <div class="decoration-container mr-2">
                        <img src="@/assets/imgs/date-decoration.svg" height="16px" alt="">
                    </div>
                    <div>
                        <slot name="date"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TimeLineItem",
}
</script>
<style scoped>

.timeLine-item-wrapper{
    position: relative;
    height: fit-content;
}
.timeline-item-container {
    background-color: var(--gray-bg-color);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 22px), calc(100% - 22px) 100%, 0 100%);
    height: 180px;
}
.arrow-decoration{
    position: absolute;
    bottom:0;
    right:0;
    z-index:1;
}
.arrow-decoration img{
    height:180px;
}

</style>