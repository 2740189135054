<template>
    <div class="prize-banner-wrapper container-fluid container-md px-0 px-lg-auto w-100">
        <div class="prize-banner-container d-flex justify-content-around align-items-center">
            <div class="prize-banner-content p-4 p-lg-5 d-flex flex-column justify-content-around align-items-around h-100">
                <h4 class="prize-banner-title text-center m-0 px-2">{{$t('totalCashPrizeTitle')}}</h4>
                <div class="prize-value-container d-flex justify-content-center align-items-center my-3 my-lg-5">
                    <div class="trophy-icon mr-4">
                        <img src="@/assets/icons/trophy.svg" height="120px" />
                    </div>
                    <h1 class="prize-value m-0 text-center">{{$t('totalValue')}}</h1>
                </div>
                <h5 class="prize-banner-subtitle text-center">{{$t("totalCashPrizeSubTitle")}}</h5>
                <div class="games-prizes-container d-flex flex-column flex-lg-row justify-content-center align-items-center mt-5">
                    <div class="prize-item d-flex align-items-center  mb-3 mb-0 ">
                        <div class="game-logo-container">
                            <img src="@/assets/imgs/logos/lol.svg" class="logo-lol" height="48px" alt="">
                        </div>
                        <div class="vertical-separator mx-2"></div>
                        <div class="">
                            <h5 class="game-item-prize-value m-0">{{$t("leagueOfLegends.total_value")}}</h5>
                        </div>
                    </div>
                    <div class="prize-item d-flex align-items-center mb-3 mb-0 mx-lg-5">
                        <div class="game-logo-container">
                            <img src="@/assets/imgs/logos/ea-fc-24.svg" class="logo-ea-fc"  height="48px" alt="">
                        </div>
                        <div class="vertical-separator mx-2"></div>
                        <div class="">
                            <h5 class="game-item-prize-value m-0">{{$t("ea_fc_24.total_value")}}</h5>
                        </div>
                    </div>
                    <div class="prize-item d-flex align-items-center  mb-3 mb-0 ">
                        <div class="game-logo-container">
                            <img src="@/assets/imgs/logos/pubg-mobile-color.svg" height="48px" alt="">
                        </div>
                        <div class="vertical-separator mx-2"></div>
                        <div class="">
                            <h5 class="game-item-prize-value m-0">{{$t("pubg.total_value")}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PrizeBanner",
}
</script>
<style scoped>
.prize-banner-container {
    clip-path: polygon(0 0, calc(100% - 56px) 0, 100% 56px, 100% 100%, 56px 100%, 0 calc(100% - 56px));
    background-color: var(--gray-bg-color);
    background-image: url("@/assets/imgs/trophy-blur.png");
    background-position: center center;
    background-size: 76%;
    overflow: hidden;
}
.prize-banner-title{
    color: black;
    font-family: "StereoGothic-800" !important;
    text-transform: uppercase;
}
.prize-banner-subtitle{
    color: black;
    font-family: "StereoGothic-800" !important;
    text-transform: uppercase;
}
.prize-value{
    color: var(--main-color);
    font-family: "sqwaredsemi_bold" !important;
    font-size: 56pt;
}

.vertical-separator{
    height: 32px;
    width: 2px;
    background-color: black;

}
.game-item-prize-value{
    color: black;
    font-family: 'sqwaredbold', sans-serif;
}
@media screen and (max-width: 768px){
    .prize-banner-container {
        background-size: 100%;
        clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
    }
    .prize-banner-title{
        font-size: 12pt;
    }
    .prize-value{
        font-size: 24pt;
    }
    .prize-banner-subtitle{
        font-size: 10pt;
    }
    .game-logo-container img{
        height: unset !important;
        width: 64px;
    }
    .logo-lol{
        width: 72px!important;
    }
    .logo-ea-fc{
        width:72px!important;
    }
    .game-item-prize-value{
        font-size: 12pt;
    }
    .vertical-separator{
        height: 10px;
    }
    .trophy-icon img{
        height: 56px;
    }
}
</style>
