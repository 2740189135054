<template>
    <div class="lgame-group-stage-match-wrapper mb-4 mr-4">
        <div class="lgame-group-stage-match-container p-2">
            <div class="match-header d-flex justify-content-between align-items-center">
                <h6 class="m-0">GROUP {{match.seed}}</h6>
                <h6 class="m-0 status-text">
                    <span v-if="match.status === 'resolved'" class="is-resolved px-2">Terminé</span>
                    <span v-if="match.status === 'pending'" class="is-pending px-2">En Cours</span>
                    <span v-if="match.status === 'reported'" class="is-reported px-2">Score déclaré</span>
                </h6>
            </div>
            <div class="match-vs w-100 d-flex justify-content-center align-items-center">
                <div class="match-container d-flex justify-content-between align-items-center my-4 mx-4 w-100">
                    <div class="match-participant w-100">
                        <div class="match-players-vs d-flex justify-content-center align-items-center w-100">
                            <div class="lgame-participant-card-container participant-card-container d-flex justify-content-start align-items-baseline mr-2 pl-1">
                                <div class="lgame-participant-card participant-card d-flex justify-content-end align-items-center m-0">
                                    <div class="d-flex flex-column justify-content-start h-100 align-items-start">
                                        <div class="lgame-participant-container d-flex justify-content-center align-items-center px-2 h-100">
                                            <h5 class="participant-name m-0"> {{match.participant.name}}</h5>
                                        </div>
                                    </div>
                                    <div class="lgame-participant-avatar-container participant-avatar-container">
                                        <img
                                            alt=""
                                            class="lgame-participant-avatar"
                                            width="100%"
                                            height="100%"
                                            :src="avatar_dir+match.participant.avatar"
                                            @error.once="replaceImage"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="lgame-participant-card-container competitor-container d-flex justify-content-end align-items-baseline m-0 pr-1">
                                <div class="lgame-participant-card competitor-card d-flex justify-content-start align-items-center m-0">
                                    <div class="lgame-participant-avatar-container">
                                        <img
                                            alt=""
                                            class="lgame-participant-avatar"
                                            width="100%"
                                            height="100%"
                                            :src="avatar_dir+match.competitor.avatar"
                                            @error.once="replaceImage"
                                        />
                                    </div>
                                    <div class="d-flex flex-column justify-content-start h-100 align-items-start">
                                        <div class="lgame-participant-container d-flex justify-content-center align-items-center px-2 h-100">
                                            <h5 class="participant-name m-0"> {{match.competitor.name}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="match-footer d-flex justify-content-center align-items-center">
                <h6 class="m-0"> Round {{match.match_round}} - Match #{{index}}</h6>
            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: "LgameGroupStageMatch",
    props:{
        match:{
            type: Object,
            default: null,
        },
        index:{
            type: Number,
            default: 0,
        }
    },
    computed:{
        participantType:{
            get(){
                return this.$store.state.currentTournament.participantType;
            }
        },
        avatar_dir:{
            get(){
                return this.participantType === 'player'
                    ? process.env.MIX_STORAGE_PATH+'/'+process.env.MIX_PLAYERS_AVATARS_DIR
                    : process.env.MIX_STORAGE_PATH+'/'+process.env.MIX_TEAMS_AVATARS_DIR;
            }
        },
    },
    methods:{
        replaceImage(e){
          e.target.src = require('@/assets/avatar-error-placeholder.png');
        }
    }
}
</script>

<style scoped>
    .lgame-group-stage-match-wrapper{
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.4));
        cursor:pointer;
    }
    .lgame-group-stage-match-container{
        clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px );
      background-color: rgba(0,0,0, 1);
    }
    .lgame-group-stage-match-container:hover{
        transform: translate(-1px, -1px);
    }

    .match-seed-number{
        color: white;
      font-family: 'sqwaredregular', sans-serif;
        font-weight: 500;
    }
    .match-players-vs{
        position:relative;
        color: white;
      font-family: 'sqwaredregular', sans-serif;
        font-weight: 500;
    }
    .match-players-vs:after{
        content: 'Vs';
        position: absolute;
        width: 32px;
        height: 100%;
        z-index: 5;
        color: #FF0000;
      font-family: 'sqwaredregular', sans-serif;
        font-weight: 900;
        font-size: 16pt;
        -webkit-text-stroke: 0.5px #FFF;
        font-style:oblique;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .status-text span{
      font-family: 'sqwaredregular', sans-serif;
        font-weight: 700;
        border-radius: 2px;
    }
    .is-pending{
        color: red;
        background-color: rgba(255, 0, 0, 0.1);
      font-family: 'sqwaredregular', sans-serif;
        font-weight: 700;
    }
    .is-resolved{
        color: #17BC04;
        background-color: rgba(23, 188, 4, 0.1);
      font-family: 'sqwaredregular', sans-serif;
        font-weight: 700;
    }
    .is-reported{
        color: #FF4F00;
        background-color: rgba(255, 79, 0, 0.1);
      font-family: 'sqwaredregular', sans-serif;
        font-weight: 700;
    }
    .participant-card-container{
        clip-path: polygon(4px 0, calc(100% - 10px) 0, 100% calc(100% - 10px), calc(100% - 6px) 100%, 12px 100%, 0 4px) !important;
    }
    .participant-card{
        clip-path: polygon(4px 0, calc(100% - 10px) 0, 100% calc(100% - 10px), calc(100% - 6px) 100%, 12px 100%, 0 4px) !important;
    }
    .participant-avatar-container{
        clip-path: polygon(4px 0, calc(100% - 10px) 0, 100% calc(100% - 10px), calc(100% - 6px) 100%, 12px 100%, 0 4px) !important;
    }
    .lgame-participant-card-container{
        min-width: 200px;
        height: 42px;
        background-color:#FF0000;
        clip-path: polygon(6px 0, calc(100% - 10px) 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 12px 100%, 0 10px);
    }
    .lgame-participant-card{
        width: 190px;
        height: 42px;
        background-color: #00171F;
        clip-path: polygon(6px 0, calc(100% - 10px) 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 12px 100%, 0 10px);
    }
    .lgame-participant-avatar-container{
        width: 54px;
        height: 42px;
        background-color:#000000;
        /*clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);*/
        clip-path: polygon(6px 0, calc(100% - 10px) 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 12px 100%, 0 10px);
    }
    .lgame-participant-avatar{
        height: 42px;
        width: 54px;
    }
    .lgame-participant-container{
        width: 128px;
        height: 42px;
        color: white;
        background-color:#00171F;
    }
    .participant-name{
        color: #AAAAAA;
      font-family: 'sqwaredregular', sans-serif;
        text-transform: capitalize;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
    }
    @media screen and (max-width: 567px){
        .lgame-group-stage-match-wrapper{
            width: 100%;
        }
        .participant-card-container,
        .participant-card,
        .participant-avatar-container {
            clip-path: polygon(2px 0, calc(100% - 8px) 0, 100% calc(100% - 8px), calc(100% - 4px) 100%, 10px 100%, 0 2px) !important;
        }
        .lgame-participant-card-container{
            min-width: 120px;
            height: 28px;
            clip-path: polygon(6px 0, calc(100% - 10px) 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 12px 100%, 0 10px);
        }
        .lgame-participant-card{
            width: 120px;
            height: 28px;
            clip-path: polygon(4px 0, calc(100% - 8px) 0, 100% calc(100% - 2px), calc(100% - 2px) 100%, 10px 100%, 0 8px);
        }

        .lgame-participant-container{
            width: 80px;
            height: 28px;
        }
        .lgame-participant-avatar-container{
            width: 38px;
            height: 28px;
            background-color:#010D11;
            clip-path: polygon(4px 0, calc(100% - 8px) 0, 100% calc(100% - 2px), calc(100% - 2px) 100%, 10px 100%, 0 8px);
        }
        .match-players-vs:after{
            width: 26px;
        }
    }

</style>
